import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './EditPlacement.scss'
import { RecruitersClientsList } from '../../../redux/action/dashboardaction'
import Loader from '../../Loader'

const EditPlacement = ({organization, data, UpdatePlacement, ToggleEdit}) => {
  const dispatch = useDispatch()
  const selectedOrganization = organization
  const RecruiterLoading = useSelector((state) => state.RecruitersClientsList.loading)
  const [Recruiters, setRecruiters] = useState()
  const [Clients, setClients] = useState()
  const [Data, setdata] = useState({
    s_no: '',
    candidate_name: '',
    name_in_timesheets: '',
    name_in_payroll_paychex: '',
    date_of_joining: '',
    client: '',
    employment_status: '',
    payment_terms: '',
    project_end: '',
    recruiter: '',
    loc: '',
    lead: '',
    pay_rate: '',
    bill_rate: '',
    actual_margin: '',
    gross_margin: '',
    source: '',
    active_inactive: 'Active',
    organization: '',
    projected_end_date: ''
  })




  const GetRecruitersClients = (option) => {
    const data = {
      "user_choice": option,
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    const successFn = (response) => {
      if(option === "recruiters"){
        setRecruiters(response.data)
      }
      else{
        setClients(response.data)
      }
    };

    const failureFn = (error) => {
      alert(error);
    };
    dispatch(RecruitersClientsList({ data, successFn, failureFn }));
  };


  useEffect(()=> {
    GetRecruitersClients("recruiters")
    GetRecruitersClients("clients")
  },[])

  // useEffect(()=> {
  //   setdata({...data})
  //   console.log('mdjbsjdbkjbd', data)
  // },[])

  useEffect(()=> {
    setdata(data)
  },[data])

  useEffect(()=> {
    if(Data.bill_rate && Data.pay_rate) {
      setdata((prev) => ({
        ...prev, gross_margin: (Data.bill_rate - Data.pay_rate).toFixed(2)
      }))
    }
  },[Data.bill_rate, Data.pay_rate])


  const handleInputChange = (e, key) => {
    const { value } = e.target;
    const option = key;

    if (option === 'date_of_joining' || option === 'projected_end_date') {
        const formattedDate = value ? new Date(value).toISOString().split('T')[0] : '';
        setdata(prev => ({
            ...prev,
            [option]: formattedDate
        }));
    }
    else if (option === 'project_end') {
        const formattedDate = value ? new Date(value).toISOString().split('T')[0] : '';
        const isActive = option === 'project_end' ? (value ? 'Inactive' : 'Active') : '';
        setdata(prev => ({
            ...prev,
            [option]: formattedDate,
            active_inactive: isActive
        }));
    }
    else {
        setdata(prev => ({
            ...prev,
            [option]: value
        }));
    }
};


const handleSubmit = (e) => {
  e.preventDefault();
  UpdatePlacement(Data);

  // setdata({
  //   s_no: '',
  //   candidate_name: '',
  //   name_in_timesheets: '',
  //   name_in_payroll_paychex: '',
  //   date_of_joining: '',
  //   client: '',
  //   employment_status: '',
  //   payment_terms: '',
  //   project_end: '',
  //   recruiter: '',
  //   loc: '',
  //   lead: '',
  //   pay_rate: '',
  //   bill_rate: '',
  //   actual_margin: '',
  //   gross_margin: '',
  //   source: '',
  //   active_inactive: 'Active',
  //   organization: '',
  //   projected_end_date: ''
  // })
  ToggleEdit(false)
}


  return (
    <main className='EditPlacement'>
      {RecruiterLoading ?
        <Loader organization={selectedOrganization}/>
        : 
        <>
          <h1 className='Header'>Edit Placement - {selectedOrganization}</h1>
          <form className='ManualForm' onSubmit={handleSubmit}>
            <section className='InputContainer'>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="organization" value={Data.organization} onChange={(e) => handleInputChange(e, 'organization')} readOnly required/>
                <label>Organization</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="candidate_name" value={Data.candidate_name} onChange={(e) => handleInputChange(e, 'candidate_name')} required/>
                <label>Candidate Name</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="name_in_timesheets" value={Data.name_in_timesheets} onChange={(e) => handleInputChange(e, 'name_in_timesheets')} />
                <label>Name in Timesheets</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="name_in_payroll_paychex" value={Data.name_in_payroll_paychex} onChange={(e) => handleInputChange(e, 'name_in_payroll_paychex')} />
                <label>Name In Payroll</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="date" name="date_of_joining_mm_dd_yyyy_field" value={Data.date_of_joining} onChange={(e) => handleInputChange(e, 'date_of_joining')} required/>
                <label>Start Date</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <select type="text" name="client" value={Data.client} onChange={(e) => handleInputChange(e, 'client')} required>
                  <option value={''} disabled> --Select One Option-- </option>
                  {Clients && Clients.map((data, index) => (
                    <option value={data} key={index}> {data} </option>
                  ))}
                </select>
                <label>Client Name</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <select type="text" name="employment_status" value={Data.employment_status} onChange={(e) => handleInputChange(e, 'employment_status')} required>
                  <option value={''} disabled> --Select One Option-- </option>
                  <option value={'Employee'}> Employee </option>
                  <option value={'Contract'}> Contract </option>
                  <option value={'1099'}> 1099 </option>
                  <option value={'Child Support'}> Child Support </option>
                </select>
                <label>Employee / Contract</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="payment_terms" value={Data.payment_terms} onChange={(e) => handleInputChange(e, 'payment_terms')} required/>
                <label>Payment Terms</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="date" name="project_end" value={Data.project_end} onChange={(e) => handleInputChange(e, 'project_end')} />
                <label>End Date</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <select type="text" name="recruiter" value={Data.recruiter} onChange={(e) => handleInputChange(e, 'recruiter')} required>
                  <option value={''} disabled> --Select One Option-- </option>
                  {Recruiters && Recruiters.map((data, index) => (
                    <option value={data.Recruiter_name} key={index}> {data.Recruiter_name} </option>
                  ))}
                </select>
                <label>Recruiter</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="loc" value={Data.loc} onChange={(e) => handleInputChange(e, 'loc')} required/>
                <label>Location</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <select type="text" name="lead" value={Data.lead} onChange={(e) => handleInputChange(e, 'lead')} required>
                  <option value={''}> --Select-- </option>
                  <option value={'LI'}>Manufacturing</option>
                  <option value={'HC'}>Healthcare</option>
                  <option value={'IT'}>Information Tech</option>
                </select>
                <label>Team</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="pay_rate" value={Data.pay_rate} onChange={(e) => handleInputChange(e, 'pay_rate')} required/>
                <label>Pay Rate</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="bill_rate" value={Data.bill_rate} onChange={(e) => handleInputChange(e, 'bill_rate')} required/>
                <label>Bill Rate</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="actual_margin" value={Data.actual_margin} onChange={(e) => handleInputChange(e, 'actual_margin')} />
                <label>Actual Margin</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="gross_margin" value={Data.gross_margin} onChange={(e) => handleInputChange(e, 'gross_margin')} required/>
                <label>Gross Margin</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="text" name="source" value={Data.source} onChange={(e) => handleInputChange(e, 'source')} />
                <label>Source</label>
              </div>
              <div className={`LabelInput ${selectedOrganization}`}>
                <input type="date" name="projected_end_date" value={Data.projected_end_date} onChange={(e) => handleInputChange(e, 'projected_end_date')} />
                <label>Projected End Date</label>
              </div>
            </section>
            <section className='ButtonsContainer'>
              <button type="submit" className={`SubmitButton ${selectedOrganization}Button`}> Update </button>
              <button className='UpdateButton' onClick={() => {ToggleEdit(false)}}> Cancel </button>
            </section>
          </form>
        </>
      }
    </main>
  )
}

export default EditPlacement