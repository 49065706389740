import React, { useEffect, useState } from 'react'
import './AddNewOnboarding.scss'
import { AddOnboardingCandidate, GetClientFacilityList, GetClientFacilityRequiredDocsList } from '../../../redux/action/dashboardaction'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../Loader'

const AddNewOnboarding = ({organization}) => {
  const selectedOrganization = organization
  const dispatch = useDispatch()
  const Role = localStorage.getItem('dummy_role')
  const User = localStorage.getItem('dummy_username')
  const [InputValue, setInputValue] = useState('')
  const [InputTime, setInputTime] = useState('')
  const [InputTimeCalculated, setInputTimeCalculated] = useState('')
  const [Selection, setSelection] = useState('hours')
  const [RefData, setRefData] =useState('')
  const [Data, setData] = useState({
    "candidate_name": "",
    "client": "",
    "facility": "",
    "user": User,
    "required_documents": '',
    "candidate_phonenumber": "",
    "job_title": "",
    "candidate_location": "",
    "client_location": "",
    "candidate_email": "",
    "remarks": "",
    "organization": selectedOrganization === 'Della' ? 'Della Infotech Inc' : selectedOrganization === 'Vezita' ? 'Vezita Tech Inc' : 'VRD Canada',
    "createdat": new Date().toISOString()
  })
  const ClientsFacilityLoading = useSelector((state) => state.GetClientFacilityList.loading)
  const ReqDocsLoading = useSelector((state) => state.GetClientFacilityRequiredDocsList.loading)
  const PostOnboardingLoading = useSelector((state) => state.AddOnboardingCandidate.loading)
  const [SelectedJobTitle, setSelectedJobTitle] = useState('')


  const GetClientsFacilities = () => {
    const data = {
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    const successFn = (response) => {
      setRefData(response.data)
      console.log('RefData', response.data)
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(GetClientFacilityList({ data, successFn, failureFn }));
  }


  const PostOnboardingCandidate = () => {
    const data = Data
    const successFn = (response) => {
      console.log('OnboardingSaved:::', response.data)
      setData({
        "candidate_name": "",
        "client": "",
        "facility": "",
        "user": "",
        "required_documents": '',
        "candidate_phonenumber": "",
        "job_title": "",
        "candidate_location": "",
        "client_location": "",
        "candidate_email": "",
        "remarks": "",
        "organization": selectedOrganization === 'Della' ? 'Della Infotech Inc' : selectedOrganization === 'Vezita' ? 'Vezita Tech Inc' : 'VRD Canada',
        "createdat": new Date().toISOString()
      })
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(AddOnboardingCandidate({ data, successFn, failureFn }));
  }

  useEffect(()=> {
    setData((prev)=> ({
      ...prev, client: ''
    }))
    GetClientsFacilities()
  },[selectedOrganization])


  const handleInputChange = (e, key) => {
    const { value } = e.target;
    const option = key;
    setData(prev => ({
        ...prev,
        [option]: value
    }));
  };


  const handleInputAddDocument = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputTimeAddDocument = (e) => {
    const value = e.target.value
    setInputTime(value)
    if (Selection === 'hours') {
      setInputTimeCalculated(value * 1)
    }
    if (Selection === 'days') {
      setInputTimeCalculated(value * 24)
    }
    if (Selection === 'months') {
      setInputTimeCalculated(value * 720)
    }
  }


  const handleSelectionChange = (e) => {
    setSelection(e.target.value)
  }

  useEffect(()=> {
    setInputTime('')
  },[Selection])

  useEffect(()=> {
    setData((prev)=> ({
      ...prev,
      facility: '',
      required_documents: ''
    }))
    setSelectedJobTitle('')
  },[Data.client])


  useEffect(()=> {
    setSelectedJobTitle('')
  },[Data.facility])

  useEffect(()=> {
    if ((Data.client && Data.facility && SelectedJobTitle) && !Data.required_documents) {
      GetClientsFacilitiesReqDocs()
    }
    else if (Data.client && Data.facility && SelectedJobTitle) {
      GetClientsFacilitiesReqDocs()
    }
  },[SelectedJobTitle])

  const GetClientsFacilitiesReqDocs = () => {
    const data = {
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
      "client": Data.client && Data.client,
      'facility': Data.facility && Data.facility,
      'job_title': SelectedJobTitle && SelectedJobTitle,
    }
    const successFn = (response) => {
      console.log(response.data[0])
      setData((prev) => ({
        ...prev,
        required_documents: response.data[0].required_documents
      }))
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(GetClientFacilityRequiredDocsList({ data, successFn, failureFn }));
  }

  const handleRemoveDoc = (keyToRemove) => {
    setData((prev) => ({
      ...prev,
      required_documents: prev.required_documents.filter(doc => {
        const keys = Object.keys(doc).filter(key => key !== 'Time' && key !== 'Reminder_details');
        return !keys.includes(keyToRemove);
      })
    }));
  };


  const handleAddDocument = (e) => {
    e.preventDefault();
    if(InputValue && InputTime) {
      const key = InputValue;
      const time = InputTimeCalculated
      setData((prev) => ({
        ...prev,
        required_documents: [
          ...prev.required_documents,
          {[key]: '', "Time": time, "Reminder_details":''}
        ]
      }));
      setInputValue('')
      setInputTime('')
      setInputTimeCalculated('')
    }
    else {
      alert('Please enter document name and its deadline correctly')
    }
  };

  const handleAddOnboarding = (e) => {
    e.preventDefault();
    PostOnboardingCandidate()
  }


  return (
    <main className='AddNewOnboarding'>
      {(ClientsFacilityLoading || ReqDocsLoading || PostOnboardingLoading) &&
        <div className='LoaderContainer'>
          <Loader organization={selectedOrganization}/>
        </div>
      }
      <h1 className='Header'> Add New Onboarding - Initiate </h1>
      <form className='FormContainer' onSubmit={handleAddOnboarding}>
        <section className='InputContainer'>
          <input type='text' value={Data.candidate_name} className={`Input ${selectedOrganization}`} placeholder='Candidate Name' onChange={(e) => {handleInputChange(e, 'candidate_name')}} required/>
          <input type='email' value={Data.candidate_email} className={`Input ${selectedOrganization}`} placeholder='Candidate Email' onChange={(e) => {handleInputChange(e, 'candidate_email')}} required/>
          <input type='text' value={Data.candidate_phonenumber} className={`Input ${selectedOrganization}`} placeholder='Candidate Phone' onChange={(e) => {handleInputChange(e, 'candidate_phonenumber')}} required/>
          <input type='text' value={Data.candidate_location} className={`Input ${selectedOrganization}`} placeholder='Candidate Location' onChange={(e) => {handleInputChange(e, 'candidate_location')}} required/>
          <input type='text' value={Data.job_title} className={`Input ${selectedOrganization}`} placeholder='Job Title' onChange={(e) => {handleInputChange(e, 'job_title')}} required/>
          <input type='text' value={Data.client_location} className={`Input ${selectedOrganization}`} placeholder='Job Location' onChange={(e) => {handleInputChange(e, 'client_location')}} required/>
          <select type='text' value={Data.client} className={`Input ${selectedOrganization}`} onChange={(e) => {handleInputChange(e, 'client')}} required>
            <option value={''} disabled>-- Select Client --</option>
            {RefData && [...new Set(RefData.map(item => item.client_name))].map((clientName, index) => (
              <option key={index}>{clientName}</option>
            ))}
          </select>
          <select value={Data.facility} className={`Input ${selectedOrganization}`} onChange={(e) => {handleInputChange(e, 'facility')}} required>
            <option value={''} disabled>-- Select Facility --</option>
            {Data.client && [...new Set(RefData.filter(data => data.client_name === Data.client).map(data => data.facility))].map((facility, index) => (
              <option key={index}>{facility}</option>
            ))}
          </select>
          <select value={SelectedJobTitle} className={`Input ${selectedOrganization}`} onChange={(e) => {setSelectedJobTitle(e.target.value)}} required>
            <option value={''} disabled>-- Select Ref Job-title --</option>
            {Data.client && [...new Set(RefData.filter(data => data.client_name === Data.client).filter(data => data.facility === Data.facility).map(data => data.job_title))].map((title, index) => (
              <option key={index} value={title}>{title}</option>
            ))}
          </select>
        </section>
        <button type='submit' className={selectedOrganization}> Save </button>
      </form>
      {(Data.client && Data.facility && SelectedJobTitle) &&
        <section className={`DocumentsContainer ${selectedOrganization}`}>
          <h1> Required Documents </h1>
          {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaManufacturing' || Role === 'DellaHealthcare' || Role === 'DellaIT') &&
            <form onSubmit={handleAddDocument}>
              <input type='text' className='Tryinput' placeholder='Document Name' value={InputValue} onChange={handleInputAddDocument}/>
              <input type='number' min={1} className='TrySelect' placeholder='Closing Deadline' value={InputTime} onChange={handleInputTimeAddDocument}/>
              <select className='TrySelect' value={Selection} onChange={handleSelectionChange}>
                <option value={'hours'}> Hours </option>
                <option value={'days'}> Days </option>
                <option value={'months'}> Months </option>
              </select>
              <button type='submit'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" fill="none">
                  <path d="M15.5 7.5H9.5V1.5C9.5 1.23478 9.39464 0.98043 9.20711 0.792893C9.01957 0.605357 8.76522 0.5 8.5 0.5C8.23478 0.5 7.98043 0.605357 7.79289 0.792893C7.60536 0.98043 7.5 1.23478 7.5 1.5V7.5H1.5C1.23478 7.5 0.98043 7.60536 0.792893 7.79289C0.605357 7.98043 0.5 8.23478 0.5 8.5C0.5 8.76522 0.605357 9.01957 0.792893 9.20711C0.98043 9.39464 1.23478 9.5 1.5 9.5H7.5V15.5C7.5 15.7652 7.60536 16.0196 7.79289 16.2071C7.98043 16.3946 8.23478 16.5 8.5 16.5C8.76522 16.5 9.01957 16.3946 9.20711 16.2071C9.39464 16.0196 9.5 15.7652 9.5 15.5V9.5H15.5C15.7652 9.5 16.0196 9.39464 16.2071 9.20711C16.3946 9.01957 16.5 8.76522 16.5 8.5C16.5 8.23478 16.3946 7.98043 16.2071 7.79289C16.0196 7.60536 15.7652 7.5 15.5 7.5Z" fill="black"/>
                </svg>
              </button>
            </form>
          }
          <article className='RequiredDocs'>
            {Data.required_documents && Data.required_documents.map((document, index) => (
              <div className='DocClose' key={index}>
                <span className={selectedOrganization}>{Object.keys(document).filter(key =>  key !== 'Time' && key !== 'Reminder_details')}</span>
                <p className='Time'>{document.Time} Hours</p>
                {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaManufacturing' || Role === 'DellaHealthcare' || Role === 'DellaIT' || Role === 'DellaHCOnboarding') &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 33 33" fill="none" onClick={() => {handleRemoveDoc(Object.keys(document).filter(key =>  key !== 'Time' && key !== 'Reminder_details')[0])}}>
                    <path d="M12.375 20.625L20.625 12.375M20.625 20.625L12.375 12.375" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                }
              </div>
            ))}
          </article>
        </section>
      }
    </main>
  )
}

export default AddNewOnboarding