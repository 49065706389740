import React from 'react'
import { ResponsivePie } from '@nivo/pie'

const ResponsivePieChart = ({datainput, color, InnerRadius, isInteractiveoption}) => {
  const dynamicdata = datainput
  const colors = color;
  const innerRadius = InnerRadius
  const handleSliceClick = (slice, event) => {
    console.log('Clicked Slice Data:', slice);
  };

  return (
    <ResponsivePie
        data={dynamicdata}
        colors={colors}
        innerRadius={innerRadius}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        onClick={handleSliceClick}
        isInteractive={isInteractiveoption}
        cornerRadius={4}
    />
  )
}

export default ResponsivePieChart