import React, { useEffect, useState } from 'react'
import './AddReferences.scss'
import { AddOnboardingReferences, RecruitersClientsList } from '../../../../redux/action/dashboardaction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Loader';

const AddReferences = ({organization, handleCancelClick}) => {
  const selectedOrganization = organization
  const dispatch = useDispatch()
  const [ClientsList, setClientsList] = useState('')
  const [InputValue, setInputValue] = useState('')
  const [InputTime, setInputTime] = useState('')
  const [InputTimeCalculated, setInputTimeCalculated] = useState('')
  const [Selection, setSelection] = useState('hours')
  const AddOnboardingLoading = useSelector((state) => state.AddOnboardingReferences.loading)
  const GetClientsRecruiter = useSelector((state) => state.RecruitersClientsList.loading)
  const [Data, setData] = useState({
    organization: selectedOrganization === 'Della' ? 'Della Infotech Inc' : selectedOrganization === 'Vezita' ? 'Vezita Tech Inc' : 'VRD Canada',
    client_name: '',
    facilities: '',
    job_title: '',
    required_documents: ''
  })

  const GetClients = () => {
    const data = {
      "user_choice": 'clients',
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    const successFn = (response) => {
      setClientsList(response.data)
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(RecruitersClientsList({ data, successFn, failureFn }));
  }

  useEffect(()=> {
    GetClients()
  },[selectedOrganization])

  useEffect(()=> {
    console.log('RequiredDocuments:::::',Data.required_documents)
  },[Data])

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    const option = key;

    setData(prev => ({...prev, [option]: value }));
  }

  const handleSelectionChange = (e) => {
    setSelection(e.target.value)
  }

  const handleInputAddDocument = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputTimeAddDocument = (e) => {
    const value = e.target.value
    setInputTime(value)
    if (Selection === 'hours') {
      setInputTimeCalculated(value * 1)
    }
    if (Selection === 'days') {
      setInputTimeCalculated(value * 24)
    }
    if (Selection === 'months') {
      setInputTimeCalculated(value * 720)
    }
  }

  useEffect(()=> {
    setInputTime('')
  },[Selection])

  const handleAddDocument = (e) => {
    e.preventDefault();
    if(InputValue && InputTime) {
      const key = InputValue;
      const time = InputTimeCalculated
      setData((prev) => ({
        ...prev,
        required_documents: [
          ...prev.required_documents,
          {[key]: '', "Time": time, "Reminder_details":''}
        ]
      }));
      setInputValue('')
      setInputTime('')
      setInputTimeCalculated('')
    }
    else {
      alert('Please enter document name and its deadline correctly')
    }
  };



  const handleRemoveDoc = (keyToRemove) => {
    setData((prev) => ({
      ...prev,
      required_documents: prev.required_documents.filter(doc => Object.keys(doc)[0] !== keyToRemove)
    }));
  };


  const handleReferenceSubmit = (e) => {
    e.preventDefault()
    if(Data.required_documents.length > 0) {
      AddReference()
    }
    else {
      alert('Include the required documents')
    }
  }


  const AddReference = () => {
    const data = Data
    const successFn = (response) => {
      console.log('Reference added successfully')
      handleCancelClick()
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(AddOnboardingReferences({ data, successFn, failureFn }));
  }


  return (
    <main className='AddReferences'>
      {(AddOnboardingLoading || GetClientsRecruiter) && 
        <div className='LoaderContainer'>
          <Loader organization={selectedOrganization}/>
        </div>
      }
      <h1 className='Header'> Create a reference list of required documents </h1>
      <article className='RefInputDoc'>
        <form className='RefInputContainer' onSubmit={handleReferenceSubmit}>
          <div className={`LabelInput ${selectedOrganization}`}>
            <input type="text" name="organization" value={Data.organization} onChange={(e) => handleInputChange(e, 'organization')} readOnly required/>
            <label>Organization</label>
          </div>
          <div className={`LabelInput ${selectedOrganization}`}>
            <select type="text" name="client_name" value={Data.client_name} onChange={(e) => handleInputChange(e, 'client_name')} required>
              <option value={''} disabled> --Select One Option-- </option>
              {ClientsList && ClientsList.map((data, index) => (
                <option value={data} key={index}> {data} </option>
              ))}
            </select>
            <label>Client Name</label>
          </div>
          <div className={`LabelInput ${selectedOrganization}`}>
            <input type="text" name="facilities" value={Data.facilities} onChange={(e) => handleInputChange(e, 'facilities')} required/>
            <label>Facility</label>
          </div>
          <div className={`LabelInput ${selectedOrganization}`}>
            <input type="text" name="jobtitle" value={Data.job_title} onChange={(e) => handleInputChange(e, 'job_title')} required/>
            <label>Job Title</label>
          </div>
          <section className='ButtonsContainer'>
            <button type="submit" className={`SubmitButton ${selectedOrganization}Button`}> Submit </button>
            <button className='UpdateButton' onClick={() => {handleCancelClick()}}> Cancel </button>
          </section>
        </form>
        <section className={`ReqDocCont ${selectedOrganization}Doc`}>
          <form onSubmit={handleAddDocument}>
            <input type='text' className='Tryinput' placeholder='Document Name' value={InputValue} onChange={handleInputAddDocument}/>
            <input type='number' min={1} className='TrySelect' placeholder='Closing Deadline' value={InputTime} onChange={handleInputTimeAddDocument}/>
            <select className='TrySelect' value={Selection} onChange={handleSelectionChange}>
              <option value={'hours'}>Hours</option>
              <option value={'days'}>Days</option>
              <option value={'months'}>Months</option>
            </select>
            <button type='submit'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" fill="none">
                <path d="M15.5 7.5H9.5V1.5C9.5 1.23478 9.39464 0.98043 9.20711 0.792893C9.01957 0.605357 8.76522 0.5 8.5 0.5C8.23478 0.5 7.98043 0.605357 7.79289 0.792893C7.60536 0.98043 7.5 1.23478 7.5 1.5V7.5H1.5C1.23478 7.5 0.98043 7.60536 0.792893 7.79289C0.605357 7.98043 0.5 8.23478 0.5 8.5C0.5 8.76522 0.605357 9.01957 0.792893 9.20711C0.98043 9.39464 1.23478 9.5 1.5 9.5H7.5V15.5C7.5 15.7652 7.60536 16.0196 7.79289 16.2071C7.98043 16.3946 8.23478 16.5 8.5 16.5C8.76522 16.5 9.01957 16.3946 9.20711 16.2071C9.39464 16.0196 9.5 15.7652 9.5 15.5V9.5H15.5C15.7652 9.5 16.0196 9.39464 16.2071 9.20711C16.3946 9.01957 16.5 8.76522 16.5 8.5C16.5 8.23478 16.3946 7.98043 16.2071 7.79289C16.0196 7.60536 15.7652 7.5 15.5 7.5Z" fill="black"/>
              </svg>
            </button>
          </form>
          <article className='DocListContainer'>
            {Data.required_documents && Data.required_documents.map((document, index) => (
              <div className='DocClose' key={index}>
                <span className={selectedOrganization}>{Object.keys(document).filter(key => key !== 'Time' && key !== 'Reminder_details')}</span>
                <p className='Time'>{document.Time} Hours</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 33 33" fill="none" onClick={() => {handleRemoveDoc(Object.keys(document)[0])}}>
                  <path d="M12.375 20.625L20.625 12.375M20.625 20.625L12.375 12.375" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            ))}
          </article>
        </section>
      </article>
    </main>
  )
}

export default AddReferences