import React, { useEffect, useState } from 'react'
import './TimeSheets.scss'
import { UpdateTimesheets } from '../../../redux/action/dashboardaction';
import { useDispatch } from 'react-redux';

const TimeSheets = ({organization}) => {
  const selectedOrganization = organization
  const dispatch = useDispatch()
  const [selection, setSelection] = useState('Margin');
  const [selectedTeam, setSelectedTeam] = useState('Overall');
  const [selectedFile, setSelectedFile] = useState(null)

  const handleSelectionChange = (e) => {
    setSelection(e.target.value)
  }

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value)
  }

  useEffect(()=> {
    console.log(selection)
  },[selection, selectedTeam, selectedOrganization]);

  useEffect(() => {
    if (selectedFile) {
      const data = {
        'file': selectedFile};
      
      const successFn = () => {
          console.log('File parsed successfully!');
        };
      const failureFn = (error) => {
          console.error('Error parsing file:', error);
        };

      dispatch(UpdateTimesheets({data, successFn, failureFn}));

      setSelectedFile(null);
    }
  }, [selectedFile, dispatch]);

  const handleFileDrop = (files) => {
    if (files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const onFileChange = (e) => {
    handleFileDrop(e.target.files);
  };


  // const handleFileUpload = () => {

  //   if (selectedFile) {
  //     const data = {
  //       'file': selectedFile};
      
  //     const successFn = () => {
  //         console.log('File uploaded successfully!');
  //       };
  //     const failureFn = (error) => {
  //         console.error('Error parsing file:', error);
  //       };

  //     dispatch(UpdateTimesheets({data, successFn, failureFn}));

  //     setSelectedFile(null);
  //   }
  // }

  return (
    <main className='TimeSheets'>
      <header className='Header'>
        <span>Active {selection} Comparison</span>
        <span className={`Apply ${selectedOrganization}`}>
          Upload
          <input type='file' className='FIleUpload' onChange={onFileChange}/>
        </span>
      </header>
      <section className='SelectionContainer'>
        <label htmlFor="MarginCandidates" className='LabelSelect'>
          Candidate Name
          <select id='MarginCandidates' className='Select' onChange={handleSelectionChange}>
            <option value={'Margin'}> Active Margin </option>
            <option value={'Candidates'}> Active Candidates </option>
          </select>
        </label>
        <label htmlFor="team" className='LabelSelect'>
          Client
          <select id='team' className='Select' onChange={handleTeamChange}>
            <option value={'Overall'}> Overall </option>
            <option value={'Manufacturing'}> Manufacturing </option>
            <option value={'Healthcare'}> Healthcare </option>
            <option value={'Information Tech'}> Information Tech </option>
          </select>
        </label>
        <label htmlFor="team" className='LabelSelect'>
          Payroll Date
          <select id='team' className='Select' onChange={handleTeamChange}>
            <option value={'Overall'}> Overall </option>
            <option value={'Manufacturing'}> Manufacturing </option>
            <option value={'Healthcare'}> Healthcare </option>
            <option value={'Information Tech'}> Information Tech </option>
          </select>
        </label>
      </section>
      <section className='FooterSection'>
        <header className="Header"> Payroll details </header>
        <table className="TableContainer">
          <thead className="HeaderContainer">
            <tr className={selectedOrganization}>
              <th> Candidate Name </th>
              <th> Start Date </th>
              <th> End Date </th>
              <th> Client </th>
              <th> Recruiter </th>
              <th> Gross Margin </th>
            </tr>
          </thead>
          <tbody className="ValueContainer">
            <tr className={selectedOrganization}>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
            </tr>
            <tr className={selectedOrganization}>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default TimeSheets