import React, { useEffect, useRef, useState } from 'react'
import './WeeklyStats.scss'
import ResponsiveLineChart from '../../../Charts/ResponsiveLineChart';
import { useDispatch, useSelector } from 'react-redux';
import { GetCandidatesWeek, WeeklyMarginCandidates } from '../../../redux/action/dashboardaction';
import Loader from '../../Loader';

const WeeklyStats = ({organization}) => {
  const Role = localStorage.getItem('dummy_role')
  const containerRef = useRef()
  const dispatch = useDispatch();
  const selectedOrganization = organization
  const [selection, setSelection] = useState('Margin');
  const [selectedTeam, setSelectedTeam] = useState((
    Role === 'DellaManufacturing' ? 'LI' : Role === 'DellaHealthcare' ? 'HC' : Role === 'DellaIT' ? 'IT' : ''
  ));
  const [Data, setData] = useState()
  const WeeklyStatusLoading = useSelector((state) => state.WeeklyMarginCandidates.loading)
  const WeeklyCandidatesLoading = useSelector((state) => state.GetCandidatesWeek.loading)
  const [checkedYears, setCheckedYears] = useState(["2024"]);
  const [TableData, setTableData] = useState('')
  const DateRange = TableData ? Object.keys(TableData) : ''
  const [PointYear, setPointYear] = useState('')
  const [PointWeek, setPointWeek] = useState('')

  const handleCheckboxChange = (year) => {
    if (checkedYears.includes(year)) {
      setCheckedYears(checkedYears.filter(item => item !== year));
    } else {
      setCheckedYears([...checkedYears, year]);
    }
  };

  const GetWeeklyStats = () => {
    const data = {
      "year": checkedYears,
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
      "lead": selectedTeam
    }
    console.log(data)
    const successFn = (response) => {
      console.log('Response::::', response.data);
      setData(response.data)
    };
  
    const failureFn = (error) => {
      console.error('Response failed:', error);
      alert(error);
    };
    dispatch(WeeklyMarginCandidates({ data, successFn, failureFn }));
  };


  const transformedMarginData = ( Data && Object.entries(Data).map(([year, weeks]) => ({
    id: year,
    data: weeks.map(week => ({
      x: week.week.replace("week", "Week "),
      y: week.data[0].margin === 0 ? null : week.data[0].margin
    }))
  })))

  const transformedCandidateData = (Data && Object.entries(Data).map(([year, weeks]) => ({
    id: year,
    data: weeks.map(week => ({
      x: week.week.replace("week", "Week "),
      y: week.data[0].active_candidate === 0 ? null : week.data[0].active_candidate
    }))
  }))
)


const GetCandidates = () => {
  setTableData('')
  const data = {
    "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
    "lead": selectedTeam,
    "year": PointYear,
    "week": PointWeek
  }

  const successFn = (response) => {
    console.log('Response::::', response.data);
    setTableData(response.data)
  };

  const failureFn = (error) => {
    console.error('Response failed:', error);
    alert(error);
  };
  dispatch(GetCandidatesWeek({ data, successFn, failureFn }));
};

useEffect(()=>{
  if (PointWeek)
    GetCandidates()
  else
    console.log('No Week Selected')

  if (containerRef.current) {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }

},[PointWeek, selectedOrganization])


  useEffect(()=> {
    GetWeeklyStats()
  },[checkedYears, selectedOrganization, selectedTeam])

  const handleSelectionChange = (e) => {
    setSelection(e.target.value)
  }

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value)
  }

  const handlePointClick = (point, event) => {
    console.log('Clicked Line Data:', point);
    setPointYear(point.serieId);
    const [, id] = point.id.split(".")
    const value = parseInt(id, 10);
    setPointWeek(value + 1)
  };

  const Colors = ['#F9003C', '#F98A00', '#1F75E0', '#09822B', '#F9D100']

  return (
    <main className='WeeklyStats' ref={containerRef}>
      <header className='HeaderSelection'>
        <span className='Header'> Active {selection} Comparison </span>
        <label htmlFor="MarginCandidates" className='LabelSelect'>
          Margin / Candidates
          <select id='MarginCandidates' className='Select' onChange={handleSelectionChange}>
            <option value={'Margin'}> Active Margin </option>
            <option value={'Candidates'}> Active Candidates </option>
          </select>
        </label>
        <label htmlFor="team" className='LabelSelect'>
          Team
          <select id='team' className='Select' onChange={handleTeamChange}>
            {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita') &&
              <option value={''}> Overall </option>
            }
            {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaManufacturing' ) &&
            <option value={'LI'}> Manufacturing </option>
            }
            {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaHealthcare' ) &&
              <option value={'HC'}> Healthcare </option>
            }
            {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaIT' ) &&
              <option value={'IT'}> Information Tech </option>
            }
          </select>
        </label>
      </header>
      <section className='CheckBoxContainer'>
        <label htmlFor='2021' className='LabelCheckBox'>
          <input type='checkbox' id='2021' className={`CheckBox ${selectedOrganization}`} onClick={() => {handleCheckboxChange("2021")}}/>
          2021
        </label>
        <label htmlFor='2022' className='LabelCheckBox'>
          <input type='checkbox' id='2022' className={`CheckBox ${selectedOrganization}`} onClick={() => {handleCheckboxChange("2022")}}/>
          2022
        </label>
        <label htmlFor='2023' className='LabelCheckBox'>
          <input type='checkbox' id='2023' className={`CheckBox ${selectedOrganization}`} onClick={() => {handleCheckboxChange("2023")}}/>
          2023
        </label>
        <label htmlFor='2024' className='LabelCheckBox'>
          <input type='checkbox' id='2024' className={`CheckBox ${selectedOrganization}`} onClick={() => {handleCheckboxChange("2024")}} checked disabled/>
          2024
        </label>
      </section>
      <article className='ChartContainer'>
        <div className={`WeeklyStatsChart ${selectedOrganization}`}>
          {WeeklyStatusLoading ? 
            <Loader organization={selectedOrganization}/>
            :
            <ResponsiveLineChart color={Colors} Data={selection === 'Margin' ? transformedMarginData : transformedCandidateData}  handlePointClick={handlePointClick}/>
          }
        </div>
      </article>
      {!WeeklyCandidatesLoading ?
        <section className='FooterSection'>
          {TableData === '' ?  '' :
            <>
              <header className="Header"> Active Candidates for the selected week({DateRange}) </header>
              <table className="TableContainer">
                <thead className="HeaderContainer">
                  <tr className={selectedOrganization}>
                    <th> Sl No </th>
                    <th> Candidate Name </th>
                    <th> Start Date </th>
                    <th> End Date </th>
                    <th> Client </th>
                    <th> Recruiter </th>
                    <th> Gross Margin </th>
                    <th> Payment Terms </th>
                    <th> Pay Rate </th>
                    <th> Bill Rate </th>
                    <th> Team </th>
                  </tr>
                </thead>
                <tbody className="ValueContainer">
                  {TableData[DateRange].candidate_names.map((data, index) => (
                    <tr className={selectedOrganization} key={index}>
                      <td> {data.s_no} </td>
                      <td> {data.candidate_name} </td>
                      {/* <td> {data.date_of_joining} </td> */}
                      <td>
                        {data.date_of_joining && (
                          <>
                            {data.date_of_joining.split('-')[1]}-
                            {data.date_of_joining.split('-')[2]}-
                            {data.date_of_joining.split('-')[0]}
                          </>
                        )}
                      </td>
                      <td>
                        {data.project_end && (
                          <>
                            {data.project_end.split('-')[1]}-
                            {data.project_end.split('-')[2]}-
                            {data.project_end.split('-')[0]}
                          </>
                        )}
                      </td>
                      {/* <td> {data.project_end} </td> */}
                      <td> {data.client} </td>
                      <td> {data.recruiter} </td>
                      <td> {data.gross_margin} </td>
                      <td> {data.payment_terms} </td>
                      <td> {data.pay_rate} </td>
                      <td> {data.bill_rate} </td>
                      <td> {data.lead} </td>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </>
          }
        </section>
        :
        <section className={`LoaderContainer ${selectedOrganization}`}>
          <Loader organization={selectedOrganization}/>
        </section>
      }
    </main>
  )
}

export default WeeklyStats