import React, { useState } from "react";
import "./DellaStats.scss";
import ActiveInactiveStats from "./ActiveInactiveStats/ActiveInactiveStats";
import WeeklyStats from "./WeeklyStats/WeeklyStats";
import DailyStats from "./DailyStats/DailyStats";
import ProjectedEnds from "./ProjectedEnds/ProjectedEnds";

const DellaStats = ({organization}) => {
  const selectedOrganization = organization
  const [selectedTab, setSelectedTab] = useState('Active')

  const handleTabClick = (option) => {
    setSelectedTab(option)
  }

  return (
    <main className="DellaStats">
      <nav className="TabsContainer">
        <span className={`Tab ${selectedOrganization} ${selectedTab === 'Active' ? 'Active': ''}`} onClick={()=> {handleTabClick('Active')}}> Active/Inactive Stats </span>
        <span className={`Tab ${selectedOrganization} ${selectedTab === 'Weekly' ? 'Active': ''}`} onClick={()=> {handleTabClick('Weekly')}}> Weekly Stats </span>
        <span className={`Tab ${selectedOrganization} ${selectedTab === 'Daily' ? 'Active': ''}`} onClick={()=> {handleTabClick('Daily')}}> Daily Stats </span>
        <span className={`Tab ${selectedOrganization} ${selectedTab === 'ProjectedEnds' ? 'Active': ''}`} onClick={()=> {handleTabClick('ProjectedEnds')}}> Projected Ends </span>
      </nav>
      <section className="StatsContainerWrapper">
        {selectedTab === 'Active' ?
          <ActiveInactiveStats organization={selectedOrganization}/> :
          selectedTab === 'Weekly' ?
          <WeeklyStats organization={selectedOrganization}/> :
          selectedTab === 'Daily' ?
          <DailyStats organization={selectedOrganization}/> :
          selectedTab === 'ProjectedEnds' ?
          <ProjectedEnds organization={selectedOrganization}/> : ''
        }
      </section>
    </main>
  );
};

export default DellaStats;
