import React from 'react'
import './Loader.css'

const Loader = ({organization}) => {
  const color = {
    background: organization === 'Della' ? '#1F75E0' : organization === 'Vezita' ? '#F98A00' : '#01497C'
  }
  return (
    <div className="loader">
      <div className="square" id="sq1" style={color}></div>
      <div className="square" id="sq2" style={color}></div>
      <div className="square" id="sq3" style={color}></div>
      <div className="square" id="sq4" style={color}></div>
      <div className="square" id="sq5" style={color}></div>
      <div className="square" id="sq6" style={color}></div>
      <div className="square" id="sq7" style={color}></div>
      <div className="square" id="sq8" style={color}></div>
      <div className="square" id="sq9" style={color}></div>
    </div>
  )
}

export default Loader