import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../api/apicall";
import { baseUrl } from "../constants/baseurl"
import { showLoading } from "../slice/loadingslice"

const StatsAsyncThunk = (method,type, url) => createAsyncThunk(type, async (payload, thunkAPI) => {
  const { data } = payload;
  const { dispatch } = thunkAPI;
  // console.log("data:::",data)
  const apiRequest = {
      method: method,
      headers: { 
                  'accept': 'application/json',
                  'Content-Type': 'application/json'
                },
      url: `${baseUrl}${url}`,
      data,
  };

  try {
      dispatch(showLoading.actions.toggleLoading(true));
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      dispatch(showLoading.actions.toggleLoading(false));

      if (response.statusFlag === true) {
          // console.log(`login action ------ ${type}`, response);
          return response;
      } else {
          console.error(`account action - ${type}`, response);

      // Check if there is an error code in the response
      const errorCode = response.message;

      // You can throw a custom error with the error code
      throw new Error(errorCode);
        }
  } catch (error) {
      dispatch(showLoading.actions.toggleLoading(false));
      throw error;
  }
});


const FileUploadAsyncThunk = (method,type, url) => createAsyncThunk(type, async (payload, thunkAPI) => {
  const { data } = payload;
  const { dispatch } = thunkAPI;
  // console.log("data:::",data)
  const apiRequest = {
      method: method,
      headers: { 
                'Content-Type': 'multipart/form-data', 
                },
      url: `${baseUrl}${url}`,
      data,
  };

  try {
      dispatch(showLoading.actions.toggleLoading(true));
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      dispatch(showLoading.actions.toggleLoading(false));

      if (response.statusFlag === true) {
          // console.log(`login action ------ ${type}`, response);
          return response;
      } else {
          console.error(`account action - ${type}`, response);

      // Check if there is an error code in the response
      const errorCode = response.message;

      // You can throw a custom error with the error code
      throw new Error(errorCode);
        }
  } catch (error) {
      dispatch(showLoading.actions.toggleLoading(false));
      throw error;
  }
});


export const ActiveInactive = StatsAsyncThunk('POST', 'ActiveInactive', 'View_margin_sheet/');
export const CreateNewPlacement = StatsAsyncThunk('POST', 'CreateNewPlacement', 'Add_margin_sheet/');
export const RequestedActiveInactive = StatsAsyncThunk('POST', 'RequestedActiveInactive', 'Fetch_MarginCounts_Details/');
export const DailyMarginCandidates = StatsAsyncThunk('POST', 'DailyMarginCandidates', 'get_margin_daily_status/');
export const RecruitersClientsList = StatsAsyncThunk('POST', 'RecruitersClientsList', 'get_user_clients_details/');
export const WeeklyMarginCandidates = StatsAsyncThunk('POST', 'WeeklyMarginCandidates', 'get_margin_listof_year/');
export const GetCandidatesWeek = StatsAsyncThunk('POST', 'GetCandidatesWeek', 'Get_Candidate_names_by_Week/');
export const GetPlacementsList = StatsAsyncThunk('POST', 'GetPlacementsList', 'Get_Margin_Timesheet_Details/');
export const UpdatePlacement = StatsAsyncThunk('PUT', 'UpdatePlacement', 'update_Margin_sheet/');
export const GetProjectedEnd = StatsAsyncThunk('POST', 'GetProjectedEnd', 'Get_Projected_End_Details/');
export const SearchPlacement = StatsAsyncThunk('POST', 'SearchPlacement', 'GlobalSearchByQuery/');

export const GetOnboardingReferences = StatsAsyncThunk('POST', 'GetOnboardingReferences', 'Get_Reference_details/');
export const AddOnboardingReferences = StatsAsyncThunk('POST', 'AddOnboardingReferences', 'Add_Reference_details/');
export const UpdateOnboardingReferences = StatsAsyncThunk('POST', 'UpdateOnboardingReferences', 'Update_reference_details/');
export const GetOnboardingList = StatsAsyncThunk('POST', 'GetOnboardingList', 'Get_Onboard_details/');
export const AddOnboardingCandidate = StatsAsyncThunk('POST', 'AddOnboardingCandidate', 'Add_Onboard_details/');
export const UploadOnboardingDoc = FileUploadAsyncThunk('POST', 'UploadOnboardingDoc', 'Update_Onboard_details/');
export const GetClientFacilityList = StatsAsyncThunk('POST', 'GetClientFacilityList', 'Get_ClientandFacility_details/');
export const GetClientFacilityRequiredDocsList = StatsAsyncThunk('POST', 'GetClientFacilityRequiredDocsList', 'Get_ClientandFacility_details/');

export const GetQuarterlyRevenue = StatsAsyncThunk('POST', 'GetQuarterlyRevenue', 'Fetch_Quarter_Month_Yearly_Details/');
export const GetMonthlyRevenue = StatsAsyncThunk('POST', 'GetMonthlyRevenue', 'Fetch_Monthly_Years_Details/');

export const UpdateTimesheets = StatsAsyncThunk('POST', 'UpdateTimesheets', 'Add_&_Update_Timesheet/');