import React, { useState } from 'react'
import './RevenueDashboard.scss'
import RevenueStats from './RevenueStats'
import QuarterlyStats from './QuarterlyStats'
import ClientsRevenueStats from './ClientsRevenueStats'

const RevenueDashboard = ({organization}) => {
  const selectedOrganization = organization
  const [selectedTab, setSelectedTab] = useState('Revenue Stats')

  const handleTabClick = (option) => {
    setSelectedTab(option)
  }
  return (
    <main className='RevenueDashboard'>
      <header className='RevenueHeader'> RevenueDashboard - {selectedOrganization === 'Della' ? 'Della Infotech Inc' : selectedOrganization === 'Vezita' ? 'Vezita Tech Inc' : 'VRD Canada'} </header>
      <nav className="TabsContainer">
        <span className={`Tab ${selectedOrganization} ${selectedTab === 'Revenue Stats' ? 'Active': ''}`} onClick={()=> {handleTabClick('Revenue Stats')}}> Revenue Stats </span>
        <span className={`Tab ${selectedOrganization} ${selectedTab === 'Quarterly' ? 'Active': ''}`} onClick={()=> {handleTabClick('Quarterly')}}> Quarterly Stats </span>
        <span className={`Tab ${selectedOrganization} ${selectedTab === 'Clients' ? 'Active': ''}`} onClick={()=> {handleTabClick('Clients')}}> Client Stats </span>
      </nav>
      <section className="StatsContainerWrapper">
        {/* <section className='Quarter'>
          <article className={`QuarterlyRevenue ${selectedOrganization}`}>
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={QuarterlyRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
            <span className='Title'>
              Quarterly Revenue - 2022
            </span>
          </article>
          <article className={`QuarterlyRevenue ${selectedOrganization}`}>
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={QuarterlyRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
            <span className='Title'>
              Quarterly Revenue - 2023
            </span>
          </article>
          <article className={`QuarterlyRevenue ${selectedOrganization}`}>
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={QuarterlyRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
            <span className='Title'>
              Quarterly Revenue - 2024
            </span>
          </article>
        </section>
        <section className='ClientsTopTen'>
          <h1 className='SubHeader'> Top 10 Clients (based on Revenue) </h1>
          <section className='TopTen'>
            <article className={`QuarterlyRevenue ${selectedOrganization}`}>
              <div className='ChartContainer'>
                <ResponsiveBarChart datainput={QuarterlyRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada} layout={'horizontal'}/>
              </div>
              <span className='Title'> Top ten Clients - 2022 </span>
            </article>
            <article className={`QuarterlyRevenue ${selectedOrganization}`}>
              <div className='ChartContainer'>
                <ResponsiveBarChart datainput={QuarterlyRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada} layout={'horizontal'}/>
              </div>
              <span className='Title'> Top ten Clients - 2023 </span>
            </article>
          </section>
        </section>
        <section className='RevenueComparison'>
          <h1 className='SubHeader'> Revenue Comparison - Clients </h1>
          <article className={`QuarterlyRevenue ${selectedOrganization}`}>
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={ClientComparisonRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
          </article>
        </section> */}
        {selectedTab === 'Revenue Stats' ? 
          <RevenueStats organization={selectedOrganization}/> :
        selectedTab === 'Quarterly' ?
          <QuarterlyStats organization={selectedOrganization}/> :
        selectedTab === 'Clients' ?
          <ClientsRevenueStats organization={selectedOrganization}/>
        :
          ''
        }
      </section>
    </main>
  )
}

export default RevenueDashboard