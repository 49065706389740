import React, { useEffect, useState } from 'react'
import StatsDashboard from '../StatsDashboard/StatsDashboard'
import Login from '../Login/Login'

const Routeindex = () => {
  const UserName = localStorage.getItem('dummy_username')
  const Password = localStorage.getItem('dummy_password')
  const [Role, setRole] = useState('')

  useEffect(()=> {
    HandleSetRole()
  },[])

  const HandleSetRole = () =>{
    if((UserName === 'kingsly@vivyacorp.com' || UserName === 'mani@vrdella.com' || UserName === 'mohamed@vrdella.com') && Password === 'vrvictory!6')
      {setRole('Admin');
      localStorage.setItem('dummy_role', 'Admin');}
    if(UserName === 'priya.d@vrdella.com' && Password === 'Mumma@229499')
      {setRole('Admin');
      localStorage.setItem('dummy_role', 'Admin');}
    if(UserName === 'mark@vrdella.com' && Password === 'markpeters!6')
      {setRole('OperationsDellaCanada');
      localStorage.setItem('dummy_role', 'OperationsDellaCanada');}
    if(UserName === 'raj@vrdella.com' && Password === 'VRRaaaj337!')
      {setRole('OperationsDellaCanada');
      localStorage.setItem('dummy_role', 'OperationsDellaCanada');}
    if(UserName === 'raj@vezita.com' && Password === 'VRRaaaj337!')
      {setRole('OperationsVezita');
      localStorage.setItem('dummy_role', 'OperationsVezita');}
    if(UserName === 'niyas@vrdella.com' && Password === 'vrdella!678')
      {setRole('DellaManufacturing');
      localStorage.setItem('dummy_role', 'DellaManufacturing');}
    if(UserName === 'karthik@vrdella.com' && Password === 'Care@123')
      {setRole('DellaHealthcare');
      localStorage.setItem('dummy_role', 'DellaHealthcare');}
    if(UserName === 'pradeap@vrdella.com' && Password === 'vrdella!6')
      {setRole('DellaIT');
      localStorage.setItem('dummy_role', 'DellaIT');}
    if(UserName === 'gokulnath@vrdella.com' && Password === 'gokulnath!6')
      {setRole('DellaHCOnboarding');
      localStorage.setItem('dummy_role', 'DellaHCOnboarding');}
    if(UserName === 'petchi@vrdella.com' && Password === 'We1c0me!')
      {setRole('DellaITLIOnboarding');
      localStorage.setItem('dummy_role', 'DellaITLIOnboarding');}
    if(UserName === 'kaitlyn@vezita.com' && Password === 'Vrdella!18')
      {setRole('DellaITLIOnboarding');
      localStorage.setItem('dummy_role', 'VezitaOnboarding');}
  }
  return (
    <>
      {Role ? 
        <StatsDashboard/>
        :
        <Login/>
      }
    </>
  )
}

export default Routeindex