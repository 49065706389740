import React, { useEffect, useState } from 'react'
import './ClientsRevenueStats.scss'
import ResponsiveBarChart from '../../../Charts/ResponsiveBarChart'
import { useDispatch } from 'react-redux'
import { GetQuarterlyRevenue } from '../../../redux/action/dashboardaction'

const ClientsRevenueStats = ({organization}) => {
  const selectedOrganization = organization
  const dispatch = useDispatch()
  const [selectedClient, setSelectedClient] = useState('Client 1')

  const handleSelectionChange = (e) => {
    setSelectedClient(e.target.value)
  }

  const GetQuarterData = () => {
    const data = {
      "year": ['2022', '2023', '2024'],
      "organization": selectedOrganization === 'Della'? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    console.log("RequestedData:::",data)
    const successFn = (response) => {
      console.log('Response::::', response.data); 
    };
  
    const failureFn = (error) => {
      console.error('Response failed:', error);
      alert(error);
    };
    dispatch(GetQuarterlyRevenue({ data, successFn, failureFn }));
  };


  useEffect(()=> {
    GetQuarterData()
  },[selectedOrganization])

  const ClientQuarterlyRevenue = [
    {
      "Quarter": "First Qtr",
      "Revenue": 80,
    },
    {
      "Quarter": "Second Qtr",
      "Revenue": 86,
    },
    {
      "Quarter": "Third Qtr",
      "Revenue": 122,
    },
    {
      "Quarter": "Fourth Qtr",
      "Revenue": 171,
    }
  ]

  const ClientMonthlyRevenue = [
    {
      "Month": "Jan",
      "Revenue": 80,
    },
    {
      "Month": "Feb",
      "Revenue": 86,
    },
    {
      "Month": "Mar",
      "Revenue": 122,
    },
    {
      "Month": "Apr",
      "Revenue": 171,
    },
    {
      "Month": "May",
      "Revenue": 80,
    },
    {
      "Month": "Jun",
      "Revenue": 86,
    },
    {
      "Month": "Jul",
      "Revenue": 122,
    },
    {
      "Month": "Aug",
      "Revenue": 171,
    },
    {
      "Month": "Sep",
      "Revenue": 80,
    },
    {
      "Month": "Oct",
      "Revenue": 86,
    },
    {
      "Month": "Nov",
      "Revenue": 122,
    },
    {
      "Month": "Dec",
      "Revenue": 171,
    },
  ]

  const Della = ['#1F75E0', '#72AAF020'];
  const Vezita = ['#F98A00', '#FFD09320'];
  const VRDCanada = ['#01497C', '#61A5C220'];

  return (
    <main className='ClientsRevenueStats'>
      <header className='HeaderSelection'>
        <h1 className='Header'> Client Revenue Details {`${selectedClient !== '' ? `- ${selectedClient}` : ''}`} </h1>
        <label htmlFor="Client" className='LabelSelect'>
          Select Client
          <select id='Client' className='Select' onChange={handleSelectionChange}>
            <option value={'Client 1'}> Client 1 </option>
            <option value={'Client 2'}> Client 2 </option>
          </select>
        </label>
      </header>
      {selectedClient &&
      <section className='ContributionProgress'>
        <article className={`Client ${selectedOrganization}`}>
          <h3 className='Title'> Quarterly Revenue </h3>
          <div className='LabelChart'>
            <label className='Label'> Quarterly Revenue </label>
            <div className='Chart'>
              <ResponsiveBarChart organization={selectedOrganization} datainput={ClientQuarterlyRevenue} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
            </div>
          </div>
          <div className='LabelChart'>
            <label className='Label'> Revenue Progress </label>
            <div className='Chart'>
              <ResponsiveBarChart organization={selectedOrganization} datainput={ClientQuarterlyRevenue} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
            </div>
          </div>
        </article>
        <article className={`Client ${selectedOrganization}`}>
          <h3 className='Title'> Monthly Revenue </h3>
          <div className='LabelChart'>
            <label className='Label'> Monthly Revenue </label>
            <div className='Chart'>
              <ResponsiveBarChart organization={selectedOrganization} datainput={ClientMonthlyRevenue} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
            </div>
          </div>
          <div className='LabelChart'>
            <label className='Label'> Revenue Progress </label>
            <div className='Chart'>
              <ResponsiveBarChart organization={selectedOrganization} datainput={ClientMonthlyRevenue} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
            </div>
          </div>
        </article>
      </section>}
      {selectedClient &&
      <section className='FooterSection'>
        <header className="Header"> Revenue Contribution Details {`${selectedClient !== '' ? `- ${selectedClient}` : ''}`} </header>
        <table className="TableContainer">
          <thead className="HeaderContainer">
            <tr className={selectedOrganization}>
              <th> Candidate Name </th>
              <th> Start Date </th>
              <th> End Date </th>
              <th> Client </th>
              <th> Recruiter </th>
              <th> Gross Margin </th>
            </tr>
          </thead>
          <tbody className="ValueContainer">
            <tr className={selectedOrganization}>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
            </tr>
            <tr className={selectedOrganization}>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
            </tr>
          </tbody>
        </table>
      </section>}
    </main>
  )
}

export default ClientsRevenueStats