import React, { useState } from 'react'
import './Placements.scss'
import AddNewPlacement from './AddNewPlacement'
import PlacementsList from './PlacementsList'

const Placements = ({organization}) => {
  const selectedOrganization = organization
  const [Toggle, setToggle] = useState(false)
  return (
    <main className='Placements'>
      <header className='HeaderSection'>
        <h1 className='PlacemnetsHeader'> Placements </h1>
        <span className={`TogglePlacement ${selectedOrganization}`} onClick={()=> {setToggle(!Toggle)}}>
          {Toggle ? 
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" id="table">
              <path d="M13.25 19.75h5.5c.55 0 1-.45 1-1v-5.5c0-.55-.45-1-1-1h-5.5c-.55 0-1 .45-1 1v5.5c0 .55.45 1 1 1zm8.37 0h5.51c.55 0 1-.45 1-1v-5.5c0-.55-.45-1-1-1h-5.51c-.55 0-1 .45-1 1v5.5c0 .55.45 1 1 1z" fill="white"/>
              <path d="M30 1H2c-.55 0-1 .45-1 1v28c0 .55.45 1 1 1h28c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1zm-1 28H3V3h26v26z" fill="white"/>
              <path d="M21.62 11.38h5.51c.55 0 1-.45 1-1V4.87c0-.55-.45-1-1-1h-5.51c-.55 0-1 .45-1 1v5.51c0 .55.45 1 1 1zm-8.37 0h5.5c.55 0 1-.45 1-1V4.87c0-.55-.45-1-1-1h-5.5c-.55 0-1 .45-1 1v5.51c0 .55.45 1 1 1zm-8.38 8.37h5.51c.55 0 1-.45 1-1v-5.5c0-.55-.45-1-1-1H4.87c-.55 0-1 .45-1 1v5.5c0 .55.45 1 1 1zm8.38 8.38h5.5c.55 0 1-.45 1-1v-5.51c0-.55-.45-1-1-1h-5.5c-.55 0-1 .45-1 1v5.51c0 .55.45 1 1 1zm-8.38 0h5.51c.55 0 1-.45 1-1v-5.51c0-.55-.45-1-1-1H4.87c-.55 0-1 .45-1 1v5.51c0 .55.45 1 1 1zm0-16.75h5.51c.55 0 1-.45 1-1V4.87c0-.55-.45-1-1-1H4.87c-.55 0-1 .45-1 1v5.51c0 .55.45 1 1 1zm16.75 16.75h5.51c.55 0 1-.45 1-1v-5.51c0-.55-.45-1-1-1h-5.51c-.55 0-1 .45-1 1v5.51c0 .55.45 1 1 1z"  fill="white"/>
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18.5316 11H12.7315V5C12.7315 4.73478 12.6296 4.48043 12.4483 4.29289C12.2671 4.10536 12.0212 4 11.7648 4C11.5084 4 11.2625 4.10536 11.0812 4.29289C10.8999 4.48043 10.7981 4.73478 10.7981 5V11H4.99794C4.74156 11 4.49568 11.1054 4.31439 11.2929C4.1331 11.4804 4.03125 11.7348 4.03125 12C4.03125 12.2652 4.1331 12.5196 4.31439 12.7071C4.49568 12.8946 4.74156 13 4.99794 13H10.7981V19C10.7981 19.2652 10.8999 19.5196 11.0812 19.7071C11.2625 19.8946 11.5084 20 11.7648 20C12.0212 20 12.2671 19.8946 12.4483 19.7071C12.6296 19.5196 12.7315 19.2652 12.7315 19V13H18.5316C18.788 13 19.0339 12.8946 19.2152 12.7071C19.3965 12.5196 19.4983 12.2652 19.4983 12C19.4983 11.7348 19.3965 11.4804 19.2152 11.2929C19.0339 11.1054 18.788 11 18.5316 11Z" fill="white"/>
            </svg>
          }
        </span>
      </header>
      <section className='TableFormContainer'>
        {Toggle ? 
          <AddNewPlacement organization={selectedOrganization}/>
          :
          <PlacementsList organization={selectedOrganization}/>
        }
      </section>
    </main>
  )
}

export default Placements