import React, { useEffect } from 'react'
import { ResponsiveCalendar } from '@nivo/calendar'

const ResponsiveCalendarChart = ({organization, from, to, Data, handleCalendarClick}) => {
  const selectedOrganization = organization;
  const From = from
  const To = to
  // const data = [
  //   {
  //     "value": 217,
  //     "day": "2016-03-09"
  //   },
  //   {
  //     "value": 37,
  //     "day": "2016-08-05"
  //   }
  // ]
  const data = Data

  const Della = ['#1F75E0', '#72AAF0']
  const Vezita = ['#F98A00', '#FFD093']
  const VRDCanada = ['#01497C', '#61A5C2']

  return (
    <ResponsiveCalendar
        data={ data }
        from={ From }
        to={ To }
        emptyColor="#eeeeee"
        colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        yearSpacing={ 40 }
        monthSpacing={ 20 }
        monthBorderColor="#ffffff"
        dayBorderWidth={ 2 }
        dayBorderColor="#ffffff"
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'row',
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: 'right-to-left'
            }
        ]}
        onClick={handleCalendarClick}
    />
  )
}

export default ResponsiveCalendarChart