import React, { useEffect, useState } from 'react'
import './PayrollDetails.scss'

const PayrollDetails = ({organization}) => {
  const selectedOrganization = organization
  const [selection, setSelection] = useState('Margin');
  const [selectedTeam, setSelectedTeam] = useState('Overall');

  const handleSelectionChange = (e) => {
    setSelection(e.target.value)
  }

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value)
  }

  useEffect(()=> {
    console.log(selection)
  },[selection, selectedTeam, selectedOrganization]);

  return (
    <main className='PayrollDetails'>
      <header className='Header'>
        Active {selection} Comparison
      </header>
      <section className='SelectionContainer'>
        <label htmlFor="MarginCandidates" className='LabelSelect'>
          Margin / Candidates
          <select id='MarginCandidates' className='Select' onChange={handleSelectionChange}>
            <option value={'Margin'}> Active Margin </option>
            <option value={'Candidates'}> Active Candidates </option>
          </select>
        </label>
        <label htmlFor="team" className='LabelSelect'>
          Team
          <select id='team' className='Select' onChange={handleTeamChange}>
            <option value={'Overall'}> Overall </option>
            <option value={'Manufacturing'}> Manufacturing </option>
            <option value={'Healthcare'}> Healthcare </option>
            <option value={'Information Tech'}> Information Tech </option>
          </select>
        </label>
      </section>
      <section className='FooterSection'>
        <header className="Header"> Payroll details </header>
        <table className="TableContainer">
          <thead className="HeaderContainer">
            <tr className={selectedOrganization}>
              <th> Candidate Name </th>
              <th> Start Date </th>
              <th> End Date </th>
              <th> Client </th>
              <th> Recruiter </th>
              <th> Gross Margin </th>
            </tr>
          </thead>
          <tbody className="ValueContainer">
            <tr className={selectedOrganization}>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
            </tr>
            <tr className={selectedOrganization}>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default PayrollDetails