import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

const ResponsiveBarChart = ({datainput, colors, layout, reverse, handleBarClick}) => {
  const data = datainput
  const layoutOption = layout
  const Colors = colors
  const reverseOption = reverse

  return (
    <ResponsiveBar
        data={data}
        margin={{ top: 10 }}
        keys={[
          Object.keys(data[0])[1]
        ]}
        indexBy={Object.keys(data[0])[0]}
        layout={layoutOption}
        reverse={reverseOption}
        padding={0.1}
        groupMode="grouped"
        enableGridY={false}
        enableLabel={false}
        colors={Colors}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        onClick={handleBarClick}
    />
  )
}

export default ResponsiveBarChart