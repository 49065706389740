import React, { useEffect, useState } from 'react'
import './ReminderConfiguration.scss'
import { useDispatch, useSelector } from 'react-redux'
import { GetClientFacilityList, GetClientFacilityRequiredDocsList, RecruitersClientsList, UpdateOnboardingReferences } from '../../../redux/action/dashboardaction'
import Loader from '../../Loader'

const ReminderConfiguration = ({organization}) => {
  const selectedOrganization = organization
  const dispatch = useDispatch()
  const [RefData, setRefData] = useState('')
  const [Data, setData] = useState({
    'client': '',
    'facility': '',
    'job_title': '',
    'required_documents': '',
    'reference_id': ''
  })
  const ClientsFacilityLoading = useSelector((state) => state.GetClientFacilityList.loading)
  const ClientFacilityReqDocsLoading = useSelector((state) => state.GetClientFacilityRequiredDocsList.loading)
  const UpdateReferenceLoading = useSelector((state) => state.UpdateOnboardingReferences.loading)
  const [Recruiters, setRecruiters] = useState('')
  const [DataSaved, setDataSaved] = useState(false)
  const [ModalData, setModalData] = useState('')


  const GetClientsFacilities = () => {
    const data = {
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    const successFn = (response) => {
        setRefData(response.data)
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(GetClientFacilityList({ data, successFn, failureFn }));
  }


  useEffect(()=>{
    GetClientsFacilities();
    GetRecruiters();
  },[selectedOrganization])

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    const option = key;
    setData(prev => ({
        ...prev,
        [option]: value
    }));
  };

  useEffect(()=> {
    setData((prev)=> ({
      ...prev,
      facility: '',
      job_title: '',
      required_documents: ''
    }))
  },[Data.client])

  useEffect(()=> {
    setData((prev)=> ({
      ...prev,
      job_title: '',
      required_documents: ''
    }))
  },[Data.facility])


  const GetClientsFacilitiesReqDocs = () => {
    const data = {
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
      "client": Data.client,
      "facility": Data.facility,
      "job_title": Data.job_title
    }
    const successFn = (response) => {
      setData((prev) => ({
        ...prev,
        'required_documents': response.data[0].required_documents,
        'reference_id': response.data[0].reference_id
      }))
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(GetClientFacilityRequiredDocsList({ data, successFn, failureFn }));
  }

  useEffect(()=> {
    setData((prev) => ({
      ...prev,
      required_documents: ''
    }))
    if ((Data.client && Data.facility && Data.job_title) && !Data.required_documents) {
      GetClientsFacilitiesReqDocs()
    }
    else if (Data.client && Data.facility && Data.job_title) {
      GetClientsFacilitiesReqDocs()
    }
  },[Data.job_title])


  const GetRecruiters = () => {
    const data = {
      "user_choice": 'recruiters',
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    const successFn = (response) => {
      setRecruiters(response.data)
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(RecruitersClientsList({ data, successFn, failureFn }));
  };


  const handleAddReminder = (e, index) => {
    e.preventDefault();

    const uservalue = e.target.querySelector('#userSelect').value;
    const time = e.target.querySelector('#timeInput').value;
    const span = e.target.querySelector('#spanSelect').value || 'hours';

    let calcTime = 0;

    if (span === 'hours') {
        calcTime = time * 1;
    } else if (span === 'days') {
        calcTime = time * 24;
    } else if (span === 'months') {
        calcTime = time * 3120;
    }

    e.target.querySelector('#userSelect').value = '';
    e.target.querySelector('#spanSelect').value = 'hours';
    e.target.querySelector('#timeInput').value = '';

    setData((prev) => {
      const updatedDocuments = prev.required_documents.map((doc, i) => {
        if (i === index) {
          return {
            ...doc,
            Reminder_details: { ...doc.Reminder_details, [uservalue]: calcTime }
          };
        }
        return doc;
      });
      return {
        ...prev,
        required_documents: updatedDocuments
      };
    });
  };


  useEffect(()=> {
    console.log('TestDataData::::::', Data)
  },[Data])


  const UpdateReference = () => {
    const data = {
      "reference_id": Data.reference_id,
      "required_document": Data.required_documents
    }
    const successFn = (response) => {
      console.log('Data Saved');
      setDataSaved(true)
      setModalData(response.data)
      setData({
        'client': '',
        'facility': '',
        'required_documents': '',
        'reference_id': ''
      })
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(UpdateOnboardingReferences({ data, successFn, failureFn }));
  };


  const handleCancelClick = () => {
    setData({
      'client': '',
      'facility': '',
      'required_documents': '',
      'reference_id': ''
    })
  }


  const handleClose = (documentIndex, reminderIndex) => {
    setData(prevData => {
      const updatedDocuments = prevData.required_documents.map((document, index) => {
        if (index === documentIndex) {
          const updatedReminderDetails = { ...document.Reminder_details };
          delete updatedReminderDetails[Object.keys(updatedReminderDetails)[reminderIndex]];
  
          return {
            ...document,
            Reminder_details: updatedReminderDetails
          };
        }
        return document;
      });
  
      return {
        ...prevData,
        required_documents: updatedDocuments
      };
    });
  };




  return (
    <main className='ReminderConfiguration'>
      {(ClientsFacilityLoading || ClientFacilityReqDocsLoading || UpdateReferenceLoading) &&
        <div className='LoaderContainer'>
          <Loader organization={selectedOrganization}/>
        </div>
      }
      <h1 className='Header'>Onboarding Reminder Configuration</h1>
      <section className='InputContainer'>
        <select className={selectedOrganization} value={Data.client} onChange={(e) => {handleInputChange(e, 'client')}} required>
          <option value={''} disabled> -- Select Client -- </option>
          {RefData && [...new Set(RefData.map(item => item.client_name))].map((clientName, index) => (
            <option key={index}>{clientName}</option>
          ))}
        </select>
        <select className={selectedOrganization} value={Data.facility} onChange={(e) => {handleInputChange(e, 'facility')}} required>
          <option value={''} disabled> -- Select Facility -- </option>
          {Data.client && [...new Set(RefData.filter(data => data.client_name === Data.client).map(data => data.facility))].map((facility, index) => (
            <option key={index}>{facility}</option>
          ))}
        </select>
        <select className={selectedOrganization} value={Data.job_title} onChange={(e) => {handleInputChange(e, 'job_title')}} required>
          <option value={''} disabled> -- Select Ref Job-Title -- </option>
          {(Data.client && Data.facility) && [...new Set(RefData.filter(data => data.client_name === Data.client).filter(data => data.facility === Data.facility).map(data => data.job_title))].map((title, index) => (
            <option key={index} value={title}>{title}</option>
          ))}
        </select>
      </section>
      {(Data.client && Data.facility && Data.job_title )&&
        <>
          <section className='RequiredDocs'>
            <h1> Required Documents </h1>
            {Data.required_documents && Data.required_documents.map((document, index) => (
              <article className='DocReminder' key={index}>
                <div className='HeaderInput'>
                  <div className='HeaderReq'>
                    <span className={selectedOrganization}>{Object.keys(document).filter(key =>  key !== 'Time' && key !== 'Reminder_details')}</span>
                    <span> {document.Time && `(Deadline: ${document.Time} Hours)`} </span>
                  </div>
                  <form onSubmit={(e) => {handleAddReminder(e, index)}}>
                    <select required id='userSelect' className={selectedOrganization}>
                      <option value={''}>--Select User--</option>
                      {Recruiters && Recruiters.map((recruiter, index) => (
                        <option key={index} value={recruiter.Email}> {recruiter.Recruiter_name} </option>
                      ))}
                    </select>
                    <input type='number' id='timeInput' min={1} max={document.Time - 2} placeholder='Remind before' required className={selectedOrganization}/>
                    <select required id='spanSelect' className={selectedOrganization}>
                      <option value={'hours'}>Hours</option>
                      <option value={'days'}>Days</option>
                      <option value={'months'}>Months</option>
                    </select>
                    <button type='submit'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" fill="none">
                        <path d="M15.5 7.5H9.5V1.5C9.5 1.23478 9.39464 0.98043 9.20711 0.792893C9.01957 0.605357 8.76522 0.5 8.5 0.5C8.23478 0.5 7.98043 0.605357 7.79289 0.792893C7.60536 0.98043 7.5 1.23478 7.5 1.5V7.5H1.5C1.23478 7.5 0.98043 7.60536 0.792893 7.79289C0.605357 7.98043 0.5 8.23478 0.5 8.5C0.5 8.76522 0.605357 9.01957 0.792893 9.20711C0.98043 9.39464 1.23478 9.5 1.5 9.5H7.5V15.5C7.5 15.7652 7.60536 16.0196 7.79289 16.2071C7.98043 16.3946 8.23478 16.5 8.5 16.5C8.76522 16.5 9.01957 16.3946 9.20711 16.2071C9.39464 16.0196 9.5 15.7652 9.5 15.5V9.5H15.5C15.7652 9.5 16.0196 9.39464 16.2071 9.20711C16.3946 9.01957 16.5 8.76522 16.5 8.5C16.5 8.23478 16.3946 7.98043 16.2071 7.79289C16.0196 7.60536 15.7652 7.5 15.5 7.5Z" fill="black"/>
                      </svg>
                    </button>
                  </form>
                </div>
                {Object.keys(document.Reminder_details).length > 0 && (
                  <div className='ReminderList'>
                    {Object.keys(document.Reminder_details).map((key, innerIndex) => (
                      <div key={innerIndex} className={selectedOrganization}>
                        <span>Remind to: <span>{key}</span></span>
                        <span>Remind before: <span>{document.Reminder_details[key]} hrs</span></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 33 33" fill="none" onClick={()=> {handleClose(index, innerIndex)}}>
                          <path d="M12.375 20.625L20.625 12.375M20.625 20.625L12.375 12.375" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                    ))}
                  </div>
                )}
              </article>
            ))}
          </section>
          <section className='ButtonContainer'>
            <button className={selectedOrganization} onClick={()=>{UpdateReference()}}> Save </button>
            <button onClick={()=> {handleCancelClick()}}> Cancel </button>
          </section>
        </>
      }
      {DataSaved &&
        <section className='Modal'>
          <article>
            <h1>Reminders for the client <span>{ModalData.client_name}</span> are saved</h1>
            <span> Facility: <span>{ModalData.facilities}</span></span>
            <button className={selectedOrganization} onClick={()=> {setDataSaved(false)}}> Okay </button>
          </article>
        </section>
      }
    </main>
  )
}

export default ReminderConfiguration