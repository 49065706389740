import React, { useEffect, useRef, useState } from 'react'
import './ActiveInactiveStats.scss'
import ResponsivePieChart from '../../../Charts/ResponsivePieChart'
import { ActiveInactive, GetPlacementsList } from '../../../redux/action/dashboardaction'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../Loader'
import TeamStatsContainer from './TeamStatsContainer'

const ActiveInactiveStats = ({organization}) => {
  const selectedOrganization = organization
  const Role = localStorage.getItem('dummy_role')
  const dispatch = useDispatch();
  const containerRef = useRef(null)
  const [ToggleModule, setToggleModule] = useState(false)
  const [Placements, setPlacements] = useState('')
  const [ActiveInactiveStatus, setActiveInactiveStatus] = useState('')
  const [Data, setData] = useState('')
  const ClientDetails = Data ? Data.candidates_details[0].active_candidates : ''
  const GetPlacementsLoading = useSelector((state) => state.GetPlacementsList.loading)

  const GetStats = () => {
    const data = {
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    console.log(data)
    const successFn = (response) => {
      setData(response.data)
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(ActiveInactive({ data, successFn, failureFn }));
  };

  useEffect(()=> {
    GetStats()
    if (containerRef.current) {
      // containerRef.current.scrollTop = containerRef.current.scrollHeight;
      containerRef.current.scrollTop = 0;
    }
  },[selectedOrganization])

  useEffect(()=> {
    // setClientDetails(Data.candidates_details[0].active_candidates)
  },[Data])

  const ActiveInactiveLoading = useSelector((state) => state.ActiveInactive.loading)
  console.log(ActiveInactiveLoading)



  // const ClientDetails = Data.data.candidates_details[0].active_candidates
  const sortedClientDetailsArray = Object.entries(ClientDetails);
  sortedClientDetailsArray.sort((a, b) => {
    const marginA = a[1].margin || 0;
    const marginB = b[1].margin || 0;
    return marginB - marginA;
  });


  const ActiveMargin = [
    {
      "id": "Active Margin",
      "value": Data ? Data.Active_margin : 0,
    },
    {
      "id": "Inactive Margin",
      "value": 200,
    }
  ];
  
  const ActiveCandidates = [
    {
      "id": "Active Candidates",
      "value": Data ? Data.Active_candidates : 0,
    },
    {
      "id": "Inactive Candidates",
      "value": Data ? Data.Inactive_candidates : 0,
    }
  ];
  
  const InactiveCandidates = [
    {
      "id": "Inactive Candidates",
      "value": Data ? Data.Inactive_candidates : 0,
    },
    {
      "id": "Active Candidates",
      "value": Data ? Data.Active_candidates : 0,
    }
  ];

  const RecruiterMargin = [
    {
      "id": Data ? `${Data.Top_Recuiter_details[0].Top_Recuiter}` : 0,
      "value": Data ? parseFloat(Data.Top_Recuiter_details[0].top_margin).toFixed(2) : 0,
    },
    {
      "id": "Others",
      "value": Data ? (Data.Active_margin - Data.Top_Recuiter_details[0].top_margin).toFixed(2) : 0,
    }
  ]

  const RecruiterCandidates = [
    {
      "id": Data ? `${Data.Top_Recuiter_details[0].Top_Recuiter}` : 0,
      "value": Data ? Data.Top_Recuiter_details[0].active_candidates : 0,
    },
    {
      "id": "Others",
      "value": Data ? Data.Active_candidates - Data.Top_Recuiter_details[0].active_candidates : 0,
    }
  ]

  const activepiecolors = ['#FFFFFF', '#FFFFFF20'];
  const Della = ['#1F75E0', '#72AAF020'];
  const Vezita = ['#F98A00', '#FFD09320'];
  const VRDCanada = ['#01497C', '#61A5C220'];

  const GetPlacements = (option) => {
    const data = {
      "limit": 5000,
      "offset": 0,
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
      "user_choice": 'margin',
      "lead": option
    }
    console.log(data)
    const successFn = (response) => {
      setPlacements(response.data[0].Margin_Data)
      console.log('RequestedPlacements:::', response.data)
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(GetPlacementsList({ data, successFn, failureFn }));
  }

  const RequestedPlacementsActive = Placements && Placements
  .map((data) => ({ ...data }))
  .filter((data) => data.active_inactive === 'Active');

  const RequestedPlacementsInactive = Placements && Placements
  .map((data) => ({ ...data }))
  .filter((data) => data.active_inactive === 'Inactive');

  const RequestedPlacements = ActiveInactiveStatus === 'Active' ? RequestedPlacementsActive : ActiveInactiveStatus === 'Inactive' ? RequestedPlacementsInactive : Placements

  const handleActiveInactiveClick = () => {
    if(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita') {
      GetPlacements('')
    }
    else if (Role === 'DellaManufacturing') {
      GetPlacements('LI')
    }
    else if (Role === 'DellaHealthcare') {
      GetPlacements('HC')
    }
    else if (Role === 'DellaIT') {
      GetPlacements('IT')
    }
  }

  const GetClientPlacements = (option) => {
    const data = {
      "limit": 5000,
      "offset": 0,
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
      "user_choice": 'margin',
      "client": option
    }
    console.log(data)
    const successFn = (response) => {
      setPlacements(response.data[0].Margin_Data)
      console.log('RequestedPlacements:::', response.data)
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(GetPlacementsList({ data, successFn, failureFn }));
  }

  return (
    <main className='ActiveInactiveStats' ref={containerRef}>
      <section className="StatsContainer">
        <article className={`ActiveStats ${selectedOrganization}`}>
          {ActiveInactiveLoading ? <Loader organization={selectedOrganization}/> :
            <>
            <header className="ActiveHeader">Active Stats</header>
            <section className="LabelValue">
              <span className="Label"> Active Margin </span>
              <span className={`Value ${selectedOrganization}Active`}>
                {`$ ${ActiveMargin[0].value}`}
              </span>
            </section>
            <section className="LabelValue">
              <span className="Label"> Active Candidates </span>
              <span className={`Value ${selectedOrganization}Active`} onClick={() => {setToggleModule(true); setActiveInactiveStatus('Active'); handleActiveInactiveClick()}}>
                <div className='PieChartContainer'>
                  <ResponsivePieChart datainput={ActiveCandidates} color={activepiecolors} InnerRadius={0.8} isInteractiveoption={false}/>
                  <label> {((ActiveCandidates[0].value / (ActiveCandidates[0].value + InactiveCandidates[0].value))*100).toFixed(2)}% </label>
                </div>
                {ActiveCandidates[0].value}
              </span>
            </section>
            <section className="LabelValue">
              <span className="Label"> Inactive Candidates </span>
              <span className={`Value ${selectedOrganization}Inactive`} onClick={() => {setToggleModule(true); setActiveInactiveStatus('Inactive'); handleActiveInactiveClick()}}>
                <div className='PieChartContainer'>
                  <ResponsivePieChart datainput={InactiveCandidates} color={activepiecolors} InnerRadius={0.8} isInteractiveoption={false}/>
                  <label> {((InactiveCandidates[0].value / (ActiveCandidates[0].value + InactiveCandidates[0].value))*100).toFixed(2)}% </label>
                </div>
                {InactiveCandidates[0].value}
              </span>
            </section>
            </>
          }
        </article>
        <article className={`ActiveStats ${selectedOrganization}`}>
          {ActiveInactiveLoading ? <Loader organization={selectedOrganization}/> :
            <>
              <header className="ActiveHeader"> {'Team Stats (Active)'} </header>
              {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaManufacturing') &&
                <section className="TeamLabelValue" onClick={() => {setToggleModule(true); GetPlacements('LI')}}>
                  <label className="TeamLabel">
                    <span>Manufacturing</span>
                    <span>
                      $ {Data && Data.LightIndustry_details[0].LI_active_margin}{' '}
                      <span>
                        ({Data && Data.LightIndustry_details[0].LI_active_candidates} candidates)
                      </span>
                    </span>
                  </label>
                  <progress
                    className={`TeamProgress ${selectedOrganization}`}
                    value={Data && Data.LightIndustry_details[0].LI_active_margin}
                    max={Data && Data.Active_margin}
                  />
                </section>
              }
              {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaHealthcare') &&
                <section className="TeamLabelValue" onClick={() => {setToggleModule(true); GetPlacements('HC')}}>
                  <label className="TeamLabel">
                    <span>Healthcare</span>
                    <span>
                      $ {Data && Data.Healthcare_details[0].HC_active_margin}{' '}
                      <span>
                        ({Data && Data.Healthcare_details[0].HC_active_candidates} candidates)
                      </span>
                    </span>
                  </label>
                  <progress
                    className={`TeamProgress ${selectedOrganization}`}
                    value={Data && Data.Healthcare_details[0].HC_active_margin}
                    max={Data && Data.Active_margin}
                  />
                </section>
              }
              {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaIT') &&
                <section className="TeamLabelValue" onClick={() => {setToggleModule(true); GetPlacements('IT')}}>
                  <label className="TeamLabel">
                    <span>Information Tech</span>
                    <span>
                      $ {Data && Data.IT_details[0].It_active_margin}{' '}
                      <span>
                        ({Data && Data.IT_details[0].It_active_candidates} candidates)
                      </span>
                    </span>
                  </label>
                  <progress
                    className={`TeamProgress ${selectedOrganization}`}
                    value={Data && Data.IT_details[0].It_active_margin}
                    max={Data && Data.Active_margin}
                  />
                </section>
              }
            </>
          }
        </article>
        {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita') &&
          <article className={`ActiveStats ${selectedOrganization}`}>
            {ActiveInactiveLoading ? <Loader organization={selectedOrganization}/> :
              <>
                <header className="ActiveHeader"> Top Recruiter </header>
                <section className="IconRecruiter">
                  <div className='Icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M8 7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7C16 8.06087 15.5786 9.07828 14.8284 9.82843C14.0783 10.5786 13.0609 11 12 11C10.9391 11 9.92172 10.5786 9.17157 9.82843C8.42143 9.07828 8 8.06087 8 7ZM8 13C6.67392 13 5.40215 13.5268 4.46447 14.4645C3.52678 15.4021 3 16.6739 3 18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H18C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 16.6739 20.4732 15.4021 19.5355 14.4645C18.5979 13.5268 17.3261 13 16 13H8Z" fill={selectedOrganization === 'Della' ? Della[0] : selectedOrganization === 'Vezita' ? Vezita[0] : VRDCanada[0]}/>
                    </svg>
                  </div>
                  <span className="Recruiter"> {Data && Data.Top_Recuiter_details[0].Top_Recuiter} </span>
                </section>
                <section className="LabelChart">
                  <label> Active Margin </label>
                  <div className='PieChartContainer'>
                    <ResponsivePieChart datainput={RecruiterMargin} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
                    <label>{Data && ((RecruiterMargin[0].value / Data.Active_margin)*100).toFixed(2)}%</label>
                  </div>
                  <span>${parseFloat(RecruiterMargin[0].value).toFixed(2)}</span>
                </section>
                <section className="LabelChart">
                  <label> Active Candidates </label>
                  <div className='PieChartContainer'>
                    <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
                    <label>{Data && ((RecruiterCandidates[0].value / Data.Active_candidates)*100).toFixed(2)}%</label>
                  </div>
                  <span> {RecruiterCandidates[0].value} </span>
                </section>
              </>
            }
          </article>
        }
      </section>
      {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita') &&
        <section className="TableHeader">
          <header className="Header"> Clients - Active & Inactive Details </header>
          {ActiveInactiveLoading ? 
            <div className={`TableLoaderContainer ${selectedOrganization}`}>
              <Loader organization={selectedOrganization}/>
            </div>
            :
            <table className="TableContainer">
              <thead className="HeaderContainer">
                <tr className={selectedOrganization}>
                  <th> Client Name </th>
                  <th> Active Candidates </th>
                  <th> Inactive Candidates </th>
                  <th> Active Margin </th>
                </tr>
              </thead>
              <tbody className="TableValueContainer">
                {sortedClientDetailsArray.map(([clientName, details], index) => (
                  <tr className={selectedOrganization} key={index}>
                    <td onClick={()=> {setToggleModule(true); GetClientPlacements(clientName)}}> {clientName} </td>
                    <td onClick={()=> {setToggleModule(true); setActiveInactiveStatus('Active'); GetClientPlacements(clientName)}}> {details.active || 0} </td>
                    <td onClick={()=> {setToggleModule(true); setActiveInactiveStatus('Inactive'); GetClientPlacements(clientName)}}> {details.Inactive || 0} </td>
                    <td> $ {details.margin?.toFixed(2) || '-'} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </section>
      }
      <TeamStatsContainer organization={selectedOrganization}/>
      {ToggleModule &&
        <section className='Modal'>
          <article className="ModalTableHeader">
            <header className="Header">
              <span> {ActiveInactiveStatus} Candidates List - <span>({RequestedPlacements.length} Candidates)</span> </span>
              <div>
                <span className={ActiveInactiveStatus === 'Active' ? 'Active' : ''} onClick={() => {setActiveInactiveStatus('Active')}}>Active</span>
                <span className={ActiveInactiveStatus === 'Inactive' ? 'Inactive' : ''} onClick={() => {setActiveInactiveStatus('Inactive')}}>Inactive</span>
                {ActiveInactiveStatus !== '' &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" onClick={() => {setActiveInactiveStatus('')}}>
                    <path d="M7.94745 6.00077L10.9772 9.03229C11.2318 9.28751 11.3747 9.63327 11.3747 9.99375C11.3747 10.3542 11.2318 10.7 10.9772 10.9552L10.9573 10.9751C10.7021 11.2297 10.3563 11.3726 9.99585 11.3726C9.63537 11.3726 9.28961 11.2297 9.03439 10.9751L6.00467 7.94354L2.97314 10.9733C2.71793 11.2278 2.37217 11.3708 2.01169 11.3708C1.65121 11.3708 1.30544 11.2278 1.05023 10.9733L1.03037 10.9534C0.775783 10.6982 0.632812 10.3524 0.632812 9.99195C0.632812 9.63146 0.775783 9.2857 1.03037 9.03049L4.06189 6.00077L1.03217 2.96924C0.777589 2.71403 0.634618 2.36826 0.634618 2.00778C0.634618 1.6473 0.777589 1.30153 1.03217 1.04632L1.05203 1.02646C1.30725 0.771877 1.65301 0.628906 2.01349 0.628906C2.37397 0.628906 2.71974 0.771877 2.97495 1.02646L6.00467 4.05799L9.0362 1.02827C9.29141 0.773682 9.63718 0.630712 9.99766 0.630712C10.3581 0.630712 10.7039 0.773682 10.9591 1.02827L10.979 1.04813C11.2336 1.30334 11.3765 1.6491 11.3765 2.00959C11.3765 2.37007 11.2336 2.71583 10.979 2.97104L7.94745 6.00077Z" fill="#1A3B68"/>
                  </svg>
                }
              </div>
            </header>
            {GetPlacementsLoading ?
              <div className={`TableLoaderContainer ${selectedOrganization}`}>
                <Loader organization={selectedOrganization}/>
              </div>
              :
              <table className="TableContainer">
                <thead className="HeaderContainer">
                  <tr className={selectedOrganization}>
                    <th> Sl No </th>
                    <th> Candidate Name </th>
                    <th> Start Date </th>
                    <th> End Date </th>
                    <th> Client </th>
                    <th> Recruiter </th>
                    <th> Gross Margin </th>
                    <th> Pay Terms </th>
                    <th> Pay Rate </th>
                    <th> Bill Rate </th>
                    <th> Team </th>
                    <th> Location </th>
                  </tr>
                </thead>
                <tbody className="TableValueContainer">
                  {Placements && RequestedPlacements.map((data, index) => (
                    <tr className={`${selectedOrganization} ${data.active_inactive}`} key={index}>
                      <td>{data.s_no}</td>
                      <td>{data.candidate_name}</td>
                      <td>
                        {data.date_of_joining && (
                          <>
                            {data.date_of_joining.split('-')[1]}-
                            {data.date_of_joining.split('-')[2]}-
                            {data.date_of_joining.split('-')[0]}
                          </>
                        )}
                      </td>
                      <td>
                        {data.project_end && (
                          <>
                            {data.project_end.split('-')[1]}-
                            {data.project_end.split('-')[2]}-
                            {data.project_end.split('-')[0]}
                          </>
                        )}
                      </td>
                      <td>{data.client}</td>
                      <td>{data.recruiter}</td>
                      <td>{data.gross_margin}</td>
                      <td>{data.payment_terms}</td>
                      <td>{data.pay_rate}</td>
                      <td>{data.bill_rate}</td>
                      <td>{data.lead}</td>
                      <td>{data.loc}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </article>
          <span className='CloseIcon' onClick={()=> {setToggleModule(false); setActiveInactiveStatus('')}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12" fill="none">
              <path d="M7.94745 6.00077L10.9772 9.03229C11.2318 9.28751 11.3747 9.63327 11.3747 9.99375C11.3747 10.3542 11.2318 10.7 10.9772 10.9552L10.9573 10.9751C10.7021 11.2297 10.3563 11.3726 9.99585 11.3726C9.63537 11.3726 9.28961 11.2297 9.03439 10.9751L6.00467 7.94354L2.97314 10.9733C2.71793 11.2278 2.37217 11.3708 2.01169 11.3708C1.65121 11.3708 1.30544 11.2278 1.05023 10.9733L1.03037 10.9534C0.775783 10.6982 0.632812 10.3524 0.632812 9.99195C0.632812 9.63146 0.775783 9.2857 1.03037 9.03049L4.06189 6.00077L1.03217 2.96924C0.777589 2.71403 0.634618 2.36826 0.634618 2.00778C0.634618 1.6473 0.777589 1.30153 1.03217 1.04632L1.05203 1.02646C1.30725 0.771877 1.65301 0.628906 2.01349 0.628906C2.37397 0.628906 2.71974 0.771877 2.97495 1.02646L6.00467 4.05799L9.0362 1.02827C9.29141 0.773682 9.63718 0.630712 9.99766 0.630712C10.3581 0.630712 10.7039 0.773682 10.9591 1.02827L10.979 1.04813C11.2336 1.30334 11.3765 1.6491 11.3765 2.00959C11.3765 2.37007 11.2336 2.71583 10.979 2.97104L7.94745 6.00077Z" fill="white"/>
            </svg>
          </span>
        </section>
      }
    </main>
  )
}

export default ActiveInactiveStats