// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes loader_5191 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loader{
  width: 100%;
  height: 100%;
  position: relative;
}

.square {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
}

#sq1 {
  margin-top: -25px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 0s infinite alternate;
}

#sq2 {
  margin-top: -25px;
  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;
}

#sq3 {
  margin-top: -25px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 150ms infinite;
}

#sq4 {
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 225ms infinite;
}

#sq5 {
  animation: loader_5191 675ms ease-in-out 300ms infinite;
}

#sq6 {
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 375ms infinite;
}

#sq7 {
  margin-top: 15px;
  margin-left: -25px;
  animation: loader_5191 675ms ease-in-out 450ms infinite;
}

#sq8 {
  margin-top: 15px;
  animation: loader_5191 675ms ease-in-out 525ms infinite;
}

#sq9 {
  margin-top: 15px;
  margin-left: 15px;
  animation: loader_5191 675ms ease-in-out 600ms infinite;
}`, "",{"version":3,"sources":["webpack://./src/StatsDashboard/Loader.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,8DAA8D;AAChE;;AAEA;EACE,iBAAiB;EACjB,gEAAgE;AAClE;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,uDAAuD;AACzD;;AAEA;EACE,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,iBAAiB;EACjB,uDAAuD;AACzD;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,gBAAgB;EAChB,uDAAuD;AACzD;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,uDAAuD;AACzD","sourcesContent":["@keyframes loader_5191 {\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n}\n\n.loader{\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.square {\n  width: 10px;\n  height: 10px;\n  border-radius: 2px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  margin-top: -5px;\n  margin-left: -5px;\n}\n\n#sq1 {\n  margin-top: -25px;\n  margin-left: -25px;\n  animation: loader_5191 675ms ease-in-out 0s infinite alternate;\n}\n\n#sq2 {\n  margin-top: -25px;\n  animation: loader_5191 675ms ease-in-out 75ms infinite alternate;\n}\n\n#sq3 {\n  margin-top: -25px;\n  margin-left: 15px;\n  animation: loader_5191 675ms ease-in-out 150ms infinite;\n}\n\n#sq4 {\n  margin-left: -25px;\n  animation: loader_5191 675ms ease-in-out 225ms infinite;\n}\n\n#sq5 {\n  animation: loader_5191 675ms ease-in-out 300ms infinite;\n}\n\n#sq6 {\n  margin-left: 15px;\n  animation: loader_5191 675ms ease-in-out 375ms infinite;\n}\n\n#sq7 {\n  margin-top: 15px;\n  margin-left: -25px;\n  animation: loader_5191 675ms ease-in-out 450ms infinite;\n}\n\n#sq8 {\n  margin-top: 15px;\n  animation: loader_5191 675ms ease-in-out 525ms infinite;\n}\n\n#sq9 {\n  margin-top: 15px;\n  margin-left: 15px;\n  animation: loader_5191 675ms ease-in-out 600ms infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
