import React, { useEffect, useState } from 'react'
import './TeamStatsContainer.scss'
import ResponsiveBarChart from '../../../Charts/ResponsiveBarChart'
import { useDispatch, useSelector } from 'react-redux'
import { RequestedActiveInactive } from '../../../redux/action/dashboardaction'
import Loader from '../../Loader'

const TeamStatsContainer = ({organization}) => {
  const dispatch = useDispatch()
  const Role = localStorage.getItem('dummy_role')

  function addDaysToDate(date, daysToAdd) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  }

  const today = new Date();
  const lastSunday = new Date(today);
  lastSunday.setDate(today.getDate() - (today.getDay() + 6));
  const end = addDaysToDate(lastSunday, 6);

  const selectedOrganization = organization
  const [selection, setSelection] = useState('Last Week')
  const [startDate, setStartDate] = useState(new Date(lastSunday).toISOString().split('T')[0])
  const [endDate, setEndDate] = useState(end.toISOString().split('T')[0])
  const [SelectedDate, setSelectedDate] = useState('')
  const [ReceivedData, setReceivedData] = useState()
  const [ReceivedBarData, setReceivedBarData] = useState()
  const [TeamsData, setTeamsData] = useState('')
  const DataLoading = useSelector((state) => state.RequestedActiveInactive.loading)
  // const [CustomToggle, setCustomToggle] = useState(false)
  const [CustomStart, setCustomStart] = useState('')
  const [CustomEnd, setCustomEnd] = useState('')

  const calculateDateRange = (input) => {
    const today = new Date();
    let start = new Date();
    let end = new Date();

    switch (input) {
      case 'Last Week':
        const lastSunday = new Date(today);
        lastSunday.setDate(today.getDate() - (today.getDay() + 6));
        start = new Date(lastSunday);
        end = addDaysToDate(lastSunday, 6);
        break;
      case 'Current Week':
        start.setDate(today.getDate() - (today.getDay() - 1));
        end.setDate(today.getDate() + (7 - today.getDay()));
        break;
      case 'Last Month':
        start.setMonth(today.getMonth() - 1, 1);
        end.setDate(0);
        break;
      case 'Current Month':
        start.setMonth(today.getMonth(), 1);
        end.setMonth(today.getMonth() + 1, 0);
        break;
      case 'Last Year':
        start.setFullYear(today.getFullYear() - 1, 0, 1);
        end.setFullYear(today.getFullYear() - 1, 11, 31);
        break;
      case 'Current Year':
        start.setFullYear(today.getFullYear(), 0, 1);
        end.setFullYear(today.getFullYear(), 11, 31);
        break;
      default:
        const [customStart, customEnd] = input.split(' to ');
        start = new Date(customStart);
        end = new Date(customEnd);
        break;
    }
    setStartDate(start.toISOString().split('T')[0])
    setEndDate(end.toISOString().split('T')[0])
    setSelection(input);
    setCustomStart('')
    setCustomEnd('')
  };

  const handleSelectionChange = (e) => {
    if(e.target.value !== 'Custom') {
      setSelection(e.target.value)
      calculateDateRange(e.target.value)
    }
    else {
      setSelection(e.target.value)
    }
  }

  const handleCustomDateChangeStart = (e) => {
    setCustomStart(e.target.value)
  }

  const handleCustomDateChangeEnd = (e) => {
    setCustomEnd(e.target.value)
  }

  const handleCustomDateSubmit = (e) => {
    e.preventDefault()
    if(CustomStart !== '' && CustomEnd !== '') {
      setEndDate(CustomEnd)
      setStartDate(CustomStart)
    }
    else if (CustomStart === '') {
      alert('Please select a start date')
    }
    else if (CustomEnd === '') {
      alert('Please select a end date')
    }
    else {
      alert('Please select both start and end dates')
    }
  }


  useEffect(()=> {
    setReceivedBarData('')
    GetDataTeams();
  },[startDate, selectedOrganization, endDate])


  const Della = ['#1F75E0', '#72AAF020'];
  const Vezita = ['#F98A00', '#FFD09320'];
  const VRDCanada = ['#01497C', '#61A5C220'];


  const ManufacturingActiveMargindata = ReceivedData && ReceivedData.date_wise_details.map(item => ({
    'date': item.date,
    'margin': item.lead_data.LI.started_margin_sum
  }));

  const HealthcareActiveMargindata = ReceivedData && ReceivedData.date_wise_details.map(item => ({
    'date': item.date,
    'margin': item.lead_data.HC.started_margin_sum
  }));

  const ITActiveMargindata = ReceivedData && ReceivedData.date_wise_details.map(item => ({
    'date': item.date,
    'margin': item.lead_data.IT.started_margin_sum
  }));



  const ManufacturingInactiveMargindata = ReceivedData && ReceivedData.date_wise_details.map(item => ({
    'date': item.date,
    'margin': item.lead_data.LI.ended_margin_sum
  }));

  const HealthcareInactiveMargindata = ReceivedData && ReceivedData.date_wise_details.map(item => ({
    'date': item.date,
    'margin': item.lead_data.HC.ended_margin_sum
  }));

  const ITInactiveMargindata = ReceivedData && ReceivedData.date_wise_details.map(item => ({
    'date': item.date,
    'margin': item.lead_data.IT.ended_margin_sum
  }));



  const GetDataTeams = () => {
    const data = {
      "from_date": startDate,
      "to_date": endDate,
      "organization": selectedOrganization === 'Della'? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    console.log("RequestedData:::",data)
    const successFn = (response) => {
      console.log('Response::::', response.data); 
      setReceivedData(response.data[0])
      setTeamsData(response.data[0].team)
    };
  
    const failureFn = (error) => {
      console.error('Response failed:', error);
      alert(error);
    };
    dispatch(RequestedActiveInactive({ data, successFn, failureFn }));
  };

  useEffect(()=> {
    console.log('TeamsData::::::', TeamsData)
    console.log('ReceivedData::::::', ReceivedData)
  },[ReceivedData])

  const handleBarClick = (bar, event) => {
    console.log('BarchartData::::',bar);
    setSelectedDate(bar.indexValue)
  }


  const BarChartLead = Role === 'DellaManufacturing' ? 'LI' : Role === 'DellaHealthcare' ? 'HC' : Role === 'DellaIT' ? 'IT' : ''


const GetBarChartData = () => {
  const data = {
    "from_date": SelectedDate,
    "to_date": SelectedDate,
    "organization": selectedOrganization === 'Della'? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
    "lead": BarChartLead
  }
  console.log("RequestedData:::",data)
  const successFn = (response) => {
    console.log('Response::::', response.data);
    setReceivedBarData(response.data)
  };

  const failureFn = (error) => {
    console.error('Response failed:', error);
    alert(error);
  };
  dispatch(RequestedActiveInactive({ data, successFn, failureFn }));
};


  const ReceivedDataLI = (ReceivedData && ReceivedData.candidate_details.length > 0) && ReceivedData.candidate_details
  .map((data) => ({ ...data }))
  .filter((data) => data.lead === 'LI');

  const ReceivedDataHC = (ReceivedData && ReceivedData.candidate_details.length > 0) && ReceivedData.candidate_details
  .map((data) => ({ ...data }))
  .filter((data) => data.lead === 'HC');

  const ReceivedDataIT = (ReceivedData && ReceivedData.candidate_details.length > 0) && ReceivedData.candidate_details
  .map((data) => ({ ...data }))
  .filter((data) => data.lead === 'IT');


  const ReceivedDataData = BarChartLead === 'LI' ? ReceivedDataLI : BarChartLead === 'HC' ? ReceivedDataHC : BarChartLead === 'IT' ? ReceivedDataIT : ReceivedData && ReceivedData.candidate_details
  
  
  const ReceivedBarDataLI = ReceivedBarData && ReceivedBarData
  .map((data) => ({ ...data }))
  .filter((data) => data.lead === 'LI');

  const ReceivedBarDataHC = ReceivedBarData && ReceivedBarData
  .map((data) => ({ ...data }))
  .filter((data) => data.lead === 'HC');

  const ReceivedBarDataIT = ReceivedBarData && ReceivedBarData
  .map((data) => ({ ...data }))
  .filter((data) => data.lead === 'IT');


  const ReceivedDataFromBar = BarChartLead === 'LI' ? ReceivedBarDataLI : BarChartLead === 'HC' ? ReceivedBarDataHC : BarChartLead === 'IT' ? ReceivedBarDataIT : ReceivedBarData


  useEffect(()=> {
    if(SelectedDate !== '')
      GetBarChartData()
  },[SelectedDate])



  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    candidateName: '',
    client: '',
    recruiter: '',
    team: ''
  });

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleFilterChange = (e, column) => {
    const value = e.target.value;
    setFilters(prevFilters => ({
      ...prevFilters,
      [column]: value
    }));
  };

  let sortedAndFilteredData = [];
  if (ReceivedBarData) {
    sortedAndFilteredData = ReceivedDataFromBar ? ReceivedDataFromBar.slice() : [];
  } else if (ReceivedData && ReceivedDataData && ReceivedDataData.length > 0) {
    sortedAndFilteredData = ReceivedDataData.slice();
  }

  if (sortBy) {
    sortedAndFilteredData.sort((a, b) => {
      let comparison = 0;
      if (a[sortBy] > b[sortBy]) {
        comparison = 1;
      } else if (a[sortBy] < b[sortBy]) {
        comparison = -1;
      }
      return sortOrder === 'desc' ? comparison * -1 : comparison;
    });
  }
  
  for (const column in filters) {
    const value = filters[column].toLowerCase();
    if (value) {
      sortedAndFilteredData = sortedAndFilteredData.filter(item => {
        const itemValue = item[column] ? item[column].toString().toLowerCase() : '';
        return itemValue.includes(value);
      });
    }
  }

  return (
    <>
      <section className="TeamStatsContainer">
        <header className="TitleOption">
          <h1 className="Title"> Teams - Active & Inactive details </h1>
          <label htmlFor="SelectionOption" className="SelectionOption">
            Period
            <select className="Option" id="SelectionOption" value={selection} onChange={handleSelectionChange}>
              <option value="Last Week"> Last Week </option>
              <option value="Current Week"> Current Week </option>
              <option value="Last Month"> Last Month </option>
              <option value="Current Month"> Current Month </option>
              <option value="Last Year"> Last Year </option>
              <option value="Current Year"> Current Year </option>
              <option value="Custom"> Custom </option>
            </select>
          </label>
        </header>
        {selection === 'Custom' &&
          <section className={`CustomDateWrapper ${selection === 'Custom' && 'Show'}`}>
            <form className='CustomDate' onSubmit={handleCustomDateSubmit}>
              <input type='date' value={CustomStart} className={selectedOrganization} onChange={handleCustomDateChangeStart}/>
              <input type='date' value={CustomEnd} className={selectedOrganization} onChange={handleCustomDateChangeEnd}/>
              <button type='submit' className={`${selectedOrganization}Button`}>Apply</button>
            </form>
          </section>
        }
        <article className="StatsTeams">
          <div className={`ActiveStats ${selectedOrganization}`}>
            {DataLoading ?
              <Loader organization={selectedOrganization}/>
              :
              <>
                <header className="ActiveHeader"> {selection}'s Active Margin (Started) </header>
                {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaManufacturing') &&
                  <div className="LabelValue">
                    <span className="Label"> Manufacturing: </span>
                    <div className={`BarChart ${selectedOrganization}`}>
                      {ManufacturingActiveMargindata && <ResponsiveBarChart datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]} handleBarClick={handleBarClick}/>}
                      <label>$ {TeamsData && TeamsData.LI.active_margin}</label>
                    </div>
                  </div>
                }
                {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaHealthcare') &&
                  <div className="LabelValue">
                    <span className="Label"> Healthcare: </span>
                    <div className={`BarChart ${selectedOrganization}`}>
                      {HealthcareActiveMargindata && <ResponsiveBarChart datainput={HealthcareActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]} handleBarClick={handleBarClick}/>}
                      <label>$ {TeamsData && TeamsData.HC.active_margin}</label>
                    </div>
                  </div>
                }
                {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaIT') &&
                  <div className="LabelValue">
                    <span className="Label"> Information Tech: </span>
                    <div className={`BarChart ${selectedOrganization}`}>
                      {ITActiveMargindata && <ResponsiveBarChart datainput={ITActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]} handleBarClick={handleBarClick}/>}
                      <label>$ {TeamsData && TeamsData.IT.active_margin}</label>
                    </div>
                  </div>
                }
              </>
            }
          </div>
          <div className={`ActiveStats ${selectedOrganization}`}>
            {DataLoading ?
              <Loader organization={selectedOrganization}/>
              :
              <>
                <header className="ActiveHeader"> {selection}'s Inactive Margin (Ended) </header>
                {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaManufacturing') &&
                  <div className="LabelValue">
                    <span className="Label"> Manufacturing: </span>
                    <div className={`BarChart ${selectedOrganization}`}>
                      {ManufacturingInactiveMargindata && <ResponsiveBarChart datainput={ManufacturingInactiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]} handleBarClick={handleBarClick}/>}
                      <label>$ {TeamsData && TeamsData.LI.inactive_margin}</label>
                    </div>
                  </div>
                }
                {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaHealthcare') &&
                  <div className="LabelValue">
                    <span className="Label"> Healthcare: </span>
                    <div className={`BarChart ${selectedOrganization}`}>
                      {HealthcareInactiveMargindata && <ResponsiveBarChart datainput={HealthcareInactiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]} handleBarClick={handleBarClick}/>}
                      <label>$ {TeamsData && TeamsData.HC.inactive_margin}</label>
                    </div>
                  </div>
                }
                {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaIT') &&
                  <div className="LabelValue">
                    <span className="Label"> Information Tech: </span>
                    <div className={`BarChart ${selectedOrganization}`}>
                      {ITInactiveMargindata && <ResponsiveBarChart datainput={ITInactiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]} handleBarClick={handleBarClick}/>}
                      <label>$ {TeamsData && TeamsData.IT.inactive_margin}</label>
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </article>
      </section>
      <section className="TableHeader">
        <header className="TitleOption">
          <span className="Title"> {`Candidates list (${SelectedDate ? SelectedDate : `${startDate} to ${endDate}`}) - Started & Ended`} </span>
        </header>
        {DataLoading ?
          <section className={`LoaderContainer ${selectedOrganization}`}>
            <Loader organization={selectedOrganization}/>
          </section>
          :
          // <table className="TableContainer">
          //   <thead className="HeaderContainer">
          //     <tr className={selectedOrganization}>
          //       <th> Sl No </th>
          //       <th> Candidate Name </th>
          //       <th> Start Date </th>
          //       <th> End Date </th>
          //       <th> Client </th>
          //       <th> Recruiter </th>
          //       <th> Gross Margin </th>
          //       <th> Payment Terms </th>
          //       <th> Pay Rate </th>
          //       <th> Bill Rate </th>
          //       <th> Team </th>
          //     </tr>
          //   </thead>
          //   <tbody className="ValueContainer">
          //     {ReceivedBarData ? ReceivedDataFromBar.map((data, index)=> (
          //       <tr className={`${selectedOrganization} ${data.active_inactive}`} key={index}>
          //         <td> {data.s_no} </td>
          //         <td> {data.candidate_name} </td>
          //         <td>
          //           {data.date_of_joining && (
          //             <>
          //               {data.date_of_joining.split('-')[1]}-
          //               {data.date_of_joining.split('-')[2]}-
          //               {data.date_of_joining.split('-')[0]}
          //             </>
          //           )}
          //         </td>
          //         <td>
          //           {data.project_end && (
          //             <>
          //               {data.project_end.split('-')[1]}-
          //               {data.project_end.split('-')[2]}-
          //               {data.project_end.split('-')[0]}
          //             </>
          //           )}
          //         </td>
          //         <td> {data.client} </td>
          //         <td> {data.recruiter} </td>
          //         <td> {data.gross_margin} </td>
          //         <td> {data.payment_terms} </td>
          //         <td> {data.pay_rate} </td>
          //         <td> {data.bill_rate} </td>
          //         <td> {data.lead} </td>
          //       </tr>))
          //       :
          //       (ReceivedData && ReceivedDataData.length > 0) && ReceivedDataData.map((data, index)=> (
          //         <tr className={`${selectedOrganization} ${data.active_inactive}`} key={index}>
          //           <td> {data.s_no} </td>
          //           <td> {data.candidate_name} </td>
          //           <td>
          //             {data.date_of_joining && (
          //               <>
          //                 {data.date_of_joining.split('-')[1]}-
          //                 {data.date_of_joining.split('-')[2]}-
          //                 {data.date_of_joining.split('-')[0]}
          //               </>
          //             )}
          //           </td>
          //           <td>
          //             {data.project_end && (
          //               <>
          //                 {data.project_end.split('-')[1]}-
          //                 {data.project_end.split('-')[2]}-
          //                 {data.project_end.split('-')[0]}
          //               </>
          //             )}
          //           </td>
          //           <td> {data.client} </td>
          //           <td> {data.recruiter} </td>
          //           <td> {data.gross_margin} </td>
          //           <td> {data.payment_terms} </td>
          //           <td> {data.pay_rate} </td>
          //           <td> {data.bill_rate} </td>
          //           <td> {data.lead} </td>
          //         </tr>
          //       ))
          //     }
          //   </tbody>
          // </table>
          <table className="TableContainer">
            <thead className="HeaderContainer">
              <tr className={selectedOrganization}>
                <th onClick={() => handleSort('s_no')}> Sl No </th>
                <th>
                  <div>
                    <span onClick={() => handleSort('candidate_name')}>Candidate Name</span>
                    {/* <input type="text" value={filters.candidate_name} onChange={(e) => handleFilterChange(e, 'candidate_name')} /> */}
                  </div>
                </th>
                <th onClick={() => handleSort('date_of_joining')}> Start Date </th>
                <th onClick={() => handleSort('project_end')}> End Date </th>
                <th>
                  <div>
                    <span onClick={() => handleSort('client')}>Client</span>
                    {/* <input type="text" value={filters.client} onChange={(e) => handleFilterChange(e, 'client')} /> */}
                  </div>
                </th>
                <th>
                  <div>
                    <span onClick={() => handleSort('recruiter')}>Recruiter</span>
                    {/* <input type="text" value={filters.recruiter} onChange={(e) => handleFilterChange(e, 'recruiter')} /> */}
                  </div>
                </th>
                <th onClick={() => handleSort('gross_margin')}> Gross Margin </th>
                <th onClick={() => handleSort('payment_terms')}> Payment Terms </th>
                <th onClick={() => handleSort('pay_rate')}> Pay Rate </th>
                <th onClick={() => handleSort('bill_rate')}> Bill Rate </th>
                <th>
                  <div>
                    <span onClick={() => handleSort('lead')}>Team</span>
                    {/* <input type="text" value={filters.lead} onChange={(e) => handleFilterChange(e, 'lead')} /> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="ValueContainer">
              {sortedAndFilteredData.map((data, index) => (
                <tr className={`${selectedOrganization} ${data.active_inactive}`} key={index}>
                  <td>{data.s_no}</td>
                  <td>{data.candidate_name}</td>
                  <td>
                    {data.date_of_joining && (
                      <>
                        {data.date_of_joining.split('-')[1]}-
                        {data.date_of_joining.split('-')[2]}-
                        {data.date_of_joining.split('-')[0]}
                      </>
                    )}
                  </td>
                  <td>
                    {data.project_end && (
                      <>
                        {data.project_end.split('-')[1]}-
                        {data.project_end.split('-')[2]}-
                        {data.project_end.split('-')[0]}
                      </>
                    )}
                  </td>
                  <td>{data.client}</td>
                  <td>{data.recruiter}</td>
                  <td>{data.gross_margin}</td>
                  <td>{data.payment_terms}</td>
                  <td>{data.pay_rate}</td>
                  <td>{data.bill_rate}</td>
                  <td>{data.lead}</td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      </section>
    </>
  )
}

export default TeamStatsContainer