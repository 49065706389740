import React, { useState } from 'react'
import './Onboardings.scss'
import OnboardingListAdd from './OnboardingListAdd'
import References from './References/References'
import ReminderConfiguration from './ReminderConfiguration'

const Onboardings = ({organization}) => {
  const Role = localStorage.getItem('dummy_role')
  const selectedOrganization = organization
  const [selectedTab, setSelectedTab] = useState('Onboardings')

  const handleTabClick = (option) => {
    setSelectedTab(option)
  }

  return (
    <main className='Onboardings'>
      <nav className="TabsContainer">
        <span className={`Tab ${selectedOrganization} ${selectedTab === 'Onboardings' ? 'Active': ''}`} onClick={()=> {handleTabClick('Onboardings')}}> Onboarding List </span>
        {(Role === 'Admin' || Role === 'DellaHCOnboarding' || Role === 'DellaITLIOnboarding' || Role === 'VezitaOnboarding' || Role === 'DellaHealthcare' || Role === 'DellaManufacturing' || Role === 'DellaIT' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita') && 
          <span className={`Tab ${selectedOrganization} ${selectedTab === 'Reference' ? 'Active': ''}`} onClick={()=> {handleTabClick('Reference')}}> Client Onboarding </span>
        }
        {(Role === 'Admin' || Role === 'DellaHCOnboarding' || Role === 'DellaITLIOnboarding' || Role === 'VezitaOnboarding' || Role === 'DellaHealthcare' || Role === 'DellaManufacturing' || Role === 'DellaIT' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita') && 
          <span className={`Tab ${selectedOrganization} ${selectedTab === 'Configure' ? 'Active': ''}`} onClick={()=> {handleTabClick('Configure')}}> Reminder Configuration </span>
        }
      </nav>
      <section className="StatsContainerWrapper">
        {
          selectedTab === 'Onboardings' ? 
          <OnboardingListAdd organization={selectedOrganization}/>
          :
          selectedTab === 'Reference' ?
          <References organization={selectedOrganization}/>
          :
          selectedTab === 'Configure' ?
          <ReminderConfiguration organization={selectedOrganization}/>
          :
          ''
        }
      </section>
    </main>
  )
}

export default Onboardings