// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6, p{
  margin: 0px;
  padding: 0px;
}

input:focus, select:focus{
  outline: none;
}

/* input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} */

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background-color: #edecec3f;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c7dffe;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #8bbdff;
}
/* 
*::selection {
  background-color: #E6F1FF;
  color: #1A3B68;
}

*::-moz-selection {
  background-color: #E6F1FF;
  color: #1A3B68;
}

*::webkit-selection {
  background-color: #E6F1FF;
  color: #1A3B68;
} */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;;;;GAIG;;AAEH;EACE,UAAU;AACZ;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;AACA;;;;;;;;;;;;;;GAcG","sourcesContent":[".App {\n  width: 100vw;\n  height: 100vh;\n  background-color: #fff;\n}\n\nh1, h2, h3, h4, h5, h6, p{\n  margin: 0px;\n  padding: 0px;\n}\n\ninput:focus, select:focus{\n  outline: none;\n}\n\n/* input {\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n} */\n\n*::-webkit-scrollbar {\n  width: 5px;\n}\n\n*::-webkit-scrollbar-track {\n  background-color: #edecec3f;\n  border-radius: 5px;\n}\n\n*::-webkit-scrollbar-thumb {\n  background-color: #c7dffe;\n  border-radius: 5px;\n}\n\n*::-webkit-scrollbar-thumb:hover {\n  background: #8bbdff;\n}\n/* \n*::selection {\n  background-color: #E6F1FF;\n  color: #1A3B68;\n}\n\n*::-moz-selection {\n  background-color: #E6F1FF;\n  color: #1A3B68;\n}\n\n*::webkit-selection {\n  background-color: #E6F1FF;\n  color: #1A3B68;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
