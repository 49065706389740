// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.References {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  background: #FFFFFF;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.References .HeaderSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.References .HeaderSection .OnboardingHeader {
  font-size: 24px;
  font-weight: 700;
  color: #4C4C66;
  margin: 0px;
  padding-left: 10px;
}
.References .HeaderSection .ToggleOnboarding {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.References .HeaderSection .Della {
  background: #1F75E0;
}
.References .HeaderSection .Vezita {
  background: #F98A00;
}
.References .HeaderSection .VRDCanada {
  background: #01497C;
}
.References .TableFormContainer {
  flex: 1 1;
  width: 100%;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/StatsDashboard/Della/Onboardings/References/References.scss"],"names":[],"mappings":"AAgBA;EACE,uBAAA;EACA,wBAAA;EACA,mBAnBkB;EAoBlB,cAAA;EACA,aAAA;EACA,sBAAA;AAfF;AAgBE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAdJ;AAeI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;AAbN;AAeI;EACE,YAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAbN;AAeI;EACE,mBAtCkB;AAyBxB;AAeI;EACE,mBAxCmB;AA2BzB;AAeI;EACE,mBA1CmB;AA6BzB;AAgBE;EACE,SAAA;EACA,WAAA;EACA,cAAA;AAdJ","sourcesContent":["$primarybackground: #FFFFFF;\n$secondarybackground: #F3F3F3;\n\n$dellaBackground: #F1F8FF;\n$vezitaBackground: #FEF3E4;\n$canadaBackground: #D3E4EB;\n\n$dellaActivebackground: #1F75E0;\n$vezitaActivebackground: #F98A00;\n$canadaActivebackground: #01497C;\n\n$dellaInactivebackground: #72AAF0;\n$vezitaInactivebackground: #FFD093;\n$canadaInactivebackground: #61A5C2;\n\n\n.References{\n  width: calc(100% - 0px);\n  height: calc(100% - 0px);\n  background: $primarybackground;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n  .HeaderSection{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    .OnboardingHeader{\n      font-size: 24px;\n      font-weight: 700;\n      color: #4C4C66;\n      margin: 0px;\n      padding-left: 10px;\n    }\n    .ToggleOnboarding{\n      height: 40px;\n      width: 40px;\n      border-radius: 10px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      cursor: pointer;\n    }\n    .Della{\n      background: $dellaActivebackground;\n    }\n    .Vezita{\n      background: $vezitaActivebackground;\n    }\n    .VRDCanada{\n      background: $canadaActivebackground;\n    }\n  }\n  .TableFormContainer{\n    flex: 1;\n    width: 100%;\n    overflow: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
