import React, { useEffect, useRef, useState } from 'react'
import './DailyStats.scss'
import ResponsiveCalendarChart from '../../../Charts/ResponsiveCalendarChart'
import { useDispatch, useSelector } from 'react-redux'
import { DailyMarginCandidates, RequestedActiveInactive } from '../../../redux/action/dashboardaction'
import Loader from '../../Loader'

const DailyStats = ({organization}) => {
  const ContainerRef = useRef(null)
  const Role = localStorage.getItem('dummy_role')
  const dispatch = useDispatch()
  const DataLoading = useSelector((state) => state.RequestedActiveInactive.loading)
  const DailyLoading = useSelector((state) => state.DailyMarginCandidates.loading)
  const selectedOrganization =  organization
  const [selection, setSelection] = useState('Started');
  const [selectedTeam, setSelectedTeam] = useState((
    Role === 'DellaManufacturing' ? 'LI' : Role === 'DellaHealthcare' ? 'HC' : Role === 'DellaIT' ? 'IT' : ''
  ));
  const [checkedYears, setCheckedYears] = useState(["2025"]);
  const [ReceivedCalendarData, setReceivedCalendarData] = useState()
  const [SelectedDate, setSelectedDate] = useState('')

  const handleSelectionChange = (e) => {
    setSelection(e.target.value)
  }

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value)
  }


  const handleCheckboxChange = (year) => {
    if (checkedYears.includes(year)) {
      setCheckedYears(checkedYears.filter(item => item !== year));
    } else {
      setCheckedYears([...checkedYears, year]);
    }
  };


  useEffect(()=> {
    setSelectedDate('')
    GetDailyCalendarData()
  },[checkedYears, selectedTeam, selectedOrganization])


  const CalendarClick = (day, event) => {
    if(day.data !== undefined) {
      console.log('SelectedDay/Calendar:::::', day);
      setSelectedDate(day.day);
    }
  }

  const GetCalendarChartData = () => {
    const data = (selection === 'Started' ?
      {
        "from_date": SelectedDate,
        "to_date": SelectedDate,
        "organization": selectedOrganization === 'Della'? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
        "lead": selectedTeam,
        "date_of_joining": SelectedDate
      }
      :
      {
        "from_date": SelectedDate,
        "to_date": SelectedDate,
        "organization": selectedOrganization === 'Della'? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
        "lead": selectedTeam,
        "project_end": SelectedDate
      }
    )
    console.log("RequestedData:::",data)
    const successFn = (response) => {
      console.log('Responsejgsuhds::::', response.data);
      setReceivedCalendarData(response.data)
    };
  
    const failureFn = (error) => {
      console.error('Response failed:', error);
      alert(error);
    };
    dispatch(RequestedActiveInactive({ data, successFn, failureFn }));
  };


  const [DailyStats, setDailyStats] = useState('')

  const GetDailyCalendarData = () => {
    const data = {
      "lead": selectedTeam,
      "year": checkedYears,
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    console.log("RequestedData:::",data)
    const successFn = (response) => {
      console.log('DailyNewResponse::::', response.data);
      setDailyStats(response.data)
    };
  
    const failureFn = (error) => {
      console.error('Response failed:', error);
      alert(error);
    };
    dispatch(DailyMarginCandidates({ data, successFn, failureFn }));
  };


  const CalendarData = DailyStats && DailyStats.map(item => {
    const value = selection === 'Started' ? item.candidate_joined_date : item.candidate_end_date
  if (value !== 0) {
    return {
      'value': value,
      'day': item.date
    };
  } else {
    return null;
  }
}).filter(item => item !== null);


  useEffect(()=> {
    if(SelectedDate !== '') {
      GetCalendarChartData()
    }
    if (ContainerRef.current) {
      ContainerRef.current.scrollTop = ContainerRef.current.scrollHeight;
    }
  },[SelectedDate])

  return (
    <main className='DailyStats' ref={ContainerRef}>
      <header className='HeaderSelection'>
        <span className='Header'> Daily stats - {selection} {selectedTeam === "LI" ? "(Manufacturing)" : selectedTeam === "HC" ? "(Healthcare)" : selectedTeam === "IT" ? "(Information Tech)" : ''} </span>
        <label htmlFor="MarginCandidates" className='LabelSelect'>
          Started / Ended
          <select id='MarginCandidates' className='Select' onChange={handleSelectionChange}>
            <option value={'Started'}> Candidates Started </option>
            <option value={'Ended'}> Candidates Ended </option>
          </select>
        </label>
        <label htmlFor="team" className='LabelSelect'>
          Team
          <select id='team' className='Select' onChange={handleTeamChange}>
            {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita') &&
              <option value={''}> Overall </option>
            }
            {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaManufacturing' ) &&
            <option value={'LI'}> Manufacturing </option>
            }
            {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaHealthcare' ) &&
              <option value={'HC'}> Healthcare </option>
            }
            {(Role === 'Admin' || Role === 'OperationsDellaCanada' || Role === 'OperationsVezita' || Role === 'DellaIT' ) &&
              <option value={'IT'}> Information Tech </option>
            }
          </select>
        </label>
      </header>
      <section className='CheckBoxContainer'>
        <label htmlFor='2022' className='LabelCheckBox'>
          <input type='checkbox' id='2022' className={`CheckBox ${selectedOrganization}`} onClick={() => {handleCheckboxChange("2022")}}/>
          2022
        </label>
        <label htmlFor='2023' className='LabelCheckBox'>
          <input type='checkbox' id='2023' className={`CheckBox ${selectedOrganization}`} onClick={() => {handleCheckboxChange("2023")}}/>
          2023
        </label>
        <label htmlFor='2024' className='LabelCheckBox'>
          <input type='checkbox' id='2024' className={`CheckBox ${selectedOrganization}`} onClick={() => {handleCheckboxChange("2024")}}/>
          2024
        </label>
        <label htmlFor='2025' className='LabelCheckBox'>
          <input type='checkbox' id='2025' className={`CheckBox ${selectedOrganization}`} onClick={() => {handleCheckboxChange("2025")}} checked disabled/>
          2025
        </label>
      </section>
      {checkedYears.includes('2022') &&
        <article className="Calendar">
          {DailyLoading ?
            <Loader organization={selectedOrganization}/>
            :
            CalendarData && <ResponsiveCalendarChart organization={selectedOrganization} from={'2022-01-01'} to={'2022-12-31'} Data={CalendarData} handleCalendarClick={CalendarClick}/>
          }
        </article>
      }
      {checkedYears.includes('2023') &&
        <article className="Calendar">
          {DailyLoading ?
            <Loader organization={selectedOrganization}/>
            :
            CalendarData && <ResponsiveCalendarChart organization={selectedOrganization} from={'2023-01-01'} to={'2023-12-31'} Data={CalendarData} handleCalendarClick={CalendarClick}/>
          }
        </article>
      }
      {checkedYears.includes('2024') &&
        <article className="Calendar">
          {DailyLoading ?
            <Loader organization={selectedOrganization}/>
            :
            CalendarData && <ResponsiveCalendarChart organization={selectedOrganization} from={'2024-01-01'} to={'2024-12-31'} Data={CalendarData} handleCalendarClick={CalendarClick}/>
          }
        </article>
      }
      {checkedYears.includes('2025') &&
        <article className="Calendar">
          {DailyLoading ?
            <Loader organization={selectedOrganization}/>
            :
            CalendarData && <ResponsiveCalendarChart organization={selectedOrganization} from={'2025-01-01'} to={'2025-12-31'} Data={CalendarData} handleCalendarClick={CalendarClick}/>
          }
        </article>
      }
      {SelectedDate &&
        <section className='FooterSection'>
          <header className="Header"> Active Candidates({SelectedDate}) </header>
          {DataLoading ?
            <div className={`LoaderContainer ${selectedOrganization}`}>
              <Loader organization={selectedOrganization}/>
            </div>
            :
            <table className="TableContainer">
              <thead className="HeaderContainer">
                <tr className={selectedOrganization}>
                  <th> Sl No </th>
                  <th> Candidate Name </th>
                  <th> Start Date </th>
                  <th> End Date </th>
                  <th> Client </th>
                  <th> Recruiter </th>
                  <th> Gross Margin </th>
                  <th> Payment Terms </th>
                  <th> Pay Rate </th>
                  <th> Bill Rate </th>
                  <th> Team </th>
                </tr>
              </thead>
              <tbody className="ValueContainer">
                {ReceivedCalendarData && ReceivedCalendarData.map((data, index)=> (
                  <tr className={selectedOrganization} key={index}>
                    <td> {data.s_no} </td>
                    <td> {data.candidate_name} </td>
                    <td>
                      {data.date_of_joining && (
                        <>
                          {data.date_of_joining.split('-')[1]}-
                          {data.date_of_joining.split('-')[2]}-
                          {data.date_of_joining.split('-')[0]}
                        </>
                      )}
                    </td>
                    <td>
                      {data.project_end && (
                        <>
                          {data.project_end.split('-')[1]}-
                          {data.project_end.split('-')[2]}-
                          {data.project_end.split('-')[0]}
                        </>
                      )}
                    </td>
                    <td> {data.client} </td>
                    <td> {data.recruiter} </td>
                    <td> {data.gross_margin} </td>
                    <td> {data.payment_terms} </td>
                    <td> {data.pay_rate} </td>
                    <td> {data.bill_rate} </td>
                    <td> {data.lead} </td>
                  </tr>
                  ))
                }
              </tbody>
            </table>
          }
        </section>
      }
    </main>
  )
}

export default DailyStats