import React, { useEffect, useRef, useState } from 'react'
import './PlacementsList.scss'
import { useDispatch, useSelector } from 'react-redux'
import { GetPlacementsList, SearchPlacement, UpdatePlacement } from '../../../redux/action/dashboardaction'
import Loader from '../../Loader'
import EditPlacement from './EditPlacement'

const PlacementsList = ({organization}) => {
  const selectedOrganization = organization
  const dispatch = useDispatch()
  const Role = localStorage.getItem('dummy_role')
  const [Placements, setPlacements] = useState('')
  const [TotalNoOfPages, setTotalNoOfPages] = useState('');
  const [itemsPerPage, setitemsPerPage] = useState(25);
  const [RequestPage, setRequestPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const ellipsis = '...';
  const maxVisiblePages = 5;
  const totalPages = Math.ceil(TotalNoOfPages);
  const CurrentRef = useRef(null)
  const PlacementsLoading = useSelector((state) => state.GetPlacementsList.loading)
  const SearchLoading = useSelector((state) => state.SearchPlacement.loading)
  // const UpdateLoading = useSelector((state) => state.UpdatePlacement.loading)
  const [Edit, setEdit] = useState(false)
  const [TranferEditdata, setTranferEditdata] = useState('')
  const [SearchQuery, setSearchQuery] = useState('')


  let pageNumbers = [];
  if (totalPages <= maxVisiblePages) {
    pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    const middlePage = Math.ceil(maxVisiblePages / 2);
    const startPage = Math.max(1, RequestPage - middlePage);
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    if (startPage > 1) {
      pageNumbers.unshift(1, ellipsis);
    }
    if (endPage < totalPages) {
      pageNumbers.push(ellipsis, totalPages);
    }
  }


  const handleItemsPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10); 
    setitemsPerPage(selectedValue); 
  };


  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value)
  }



  const GetPlacements = () => {
    const offset = (RequestPage * itemsPerPage) - (itemsPerPage);
    const Lead = Role === 'DellaManufacturing' ? 'LI' : Role === 'DellaHealthcare' ? 'HC' : Role === 'DellaIT' ? 'IT' : ''
    const data = {
      "limit": itemsPerPage,
      "offset": offset,
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada',
      "user_choice": 'margin',
      "lead": Lead
    }
    console.log(data)
    const successFn = (response) => {
      setPlacements(response.data[0].Margin_Data)
      console.log('Placements:::', response.data)
      setTotalNoOfPages(Math.ceil(response.data[0].Count_of_Margin/itemsPerPage))
      console.log('TotalPages::::', Math.ceil(response.data[0].Count_of_Margin/itemsPerPage))
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(GetPlacementsList({ data, successFn, failureFn }));
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const data = {
      "user_choice_model": "margin",
      "query": SearchQuery,
      "organization": selectedOrganization === 'Della' ? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    console.log(data)
    const successFn = (response) => {
      console.log(response.data)
      setPlacements(response.data[0].MarginSheet_Data)
      setSearchQuery('')
    };
  
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(SearchPlacement({ data, successFn, failureFn }));
  }

  useEffect(() => {
    GetPlacements()
    if (CurrentRef.current) {
      CurrentRef.current.scrollTop = 0;
      CurrentRef.current.scrollLeft = 0;
    }
    setEdit(false)
  },[RequestPage, itemsPerPage, selectedOrganization])

  useEffect(()=> {
    setRequestPage(1)
    setitemsPerPage(25)
    GetPlacements()
  },[selectedOrganization])

  const handleEditPlacement = (d) => {
    setEdit(true);
    console.log('Upadte Requested Data:::::::', d)
    setTranferEditdata(d);
  }


  const ToggleEditPlacement = (option) => {
    setEdit(option)
    GetPlacements()

    if (CurrentRef.current) {
      CurrentRef.current.scrollTop = 0;
      CurrentRef.current.scrollLeft = 0;
    }
  }


  const updatePlacement = (putData) => {
    const data = putData
    const successFn = (response) => {
      console.log('Placement Updated', response.data)
    };
    const failureFn = (error) => {
      alert(error);
    };
    dispatch(UpdatePlacement({ data, successFn, failureFn }));
  }

  return (
    <main className='PlacementsList'>
      {SearchLoading &&
          <section className={`LoaderContainer ${selectedOrganization}`}>
            <Loader organization={selectedOrganization}/>
          </section>
        }
      <header className={`SearchContainer ${selectedOrganization}Border`}>
        <form onSubmit={handleSearchSubmit}><input type="text" value={SearchQuery} onChange={handleSearchInput}/></form>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={handleSearchSubmit}>
          <path d="M11.7668 20.7561C16.7313 20.7561 20.7558 16.7316 20.7558 11.7671C20.7558 6.80259 16.7313 2.77808 11.7668 2.77808C6.80234 2.77808 2.77783 6.80259 2.77783 11.7671C2.77783 16.7316 6.80234 20.7561 11.7668 20.7561Z" stroke={selectedOrganization === 'Della' ? '#1F75E0' : selectedOrganization === 'Vezita' ? '#F98A00' : '#01497C'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M18.0181 18.4851L21.5421 22" stroke={selectedOrganization === 'Della' ? '#1F75E0' : selectedOrganization === 'Vezita' ? '#F98A00' : '#01497C'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </header>
      <section className='PlacementsTable'>
        {PlacementsLoading &&
          <section className={`LoaderContainer ${selectedOrganization}`}>
            <Loader organization={selectedOrganization}/>
          </section>
        }
        <section className='Table' ref={CurrentRef}>
          {Placements.length > 0 ?
            <table className="TableContainer">
              <thead className="HeaderContainer">
                <tr className={selectedOrganization}>
                  <th> Sl No </th>
                  <th> Candidate Name </th>
                  <th> Start Date </th>
                  <th> End Date </th>
                  <th> Client </th>
                  <th> Recruiter </th>
                  <th> Gross Margin </th>
                  <th> Pay Terms </th>
                  <th> Pay Rate </th>
                  <th> Bill Rate </th>
                  <th> Team </th>
                  <th> Edit </th>
                </tr>
              </thead>
              <tbody className="ValueContainer">
                {Placements && Placements.map((data, index) => (
                  <tr className={`${selectedOrganization} ${data.active_inactive}`} key={index}>
                    <td>{data.s_no}</td>
                    <td>{data.candidate_name}</td>
                    <td>
                      {data.date_of_joining && (
                        <>
                          {data.date_of_joining.split('-')[1]}-
                          {data.date_of_joining.split('-')[2]}-
                          {data.date_of_joining.split('-')[0]}
                        </>
                      )}
                    </td>
                    <td>
                      {data.project_end && (
                        <>
                          {data.project_end.split('-')[1]}-
                          {data.project_end.split('-')[2]}-
                          {data.project_end.split('-')[0]}
                        </>
                      )}
                    </td>
                    <td>{data.client}</td>
                    <td>{data.recruiter}</td>
                    <td>{data.gross_margin}</td>
                    <td>{data.payment_terms}</td>
                    <td>{data.pay_rate}</td>
                    <td>{data.bill_rate}</td>
                    <td>{data.lead}</td>
                    <td>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 64 64" id="editing" onClick={() => {handleEditPlacement(data)}}>
                      <path fill={selectedOrganization === 'Della' ? '#1F75E0' : selectedOrganization === 'Vezita' ? '#F98A00' : '#01497C'} fillRule="evenodd" d="M33.8518 5.45688C38.461 0.847707 45.934 0.847708 50.5431 5.45688C55.1523 10.0661 55.1523 17.539 50.5431 22.1482L23.9171 48.7742C22.8795 49.8118 21.5148 50.4585 20.0547 50.6045L6.19901 51.9901C5.60212 52.0498 5.00996 51.8384 4.58579 51.4142C4.16162 50.99 3.95024 50.3979 4.00993 49.801L5.3955 35.9453C5.54151 34.4852 6.18818 33.1205 7.22578 32.0829L10.6188 28.6899C11.3999 27.9089 12.6662 27.9089 13.4472 28.6899C14.2283 29.471 14.2283 30.7373 13.4472 31.5183L10.0542 34.9114C9.66953 35.296 9.42978 35.802 9.37565 36.3433L8.23331 47.7667L19.6567 46.6244C20.198 46.5702 20.704 46.3305 21.0886 45.9458L42.4497 24.5848L31.4153 13.5503L20.5183 24.4473C19.7373 25.2283 18.4709 25.2283 17.6899 24.4473C16.9088 23.6662 16.9088 22.3999 17.6899 21.6188L33.8518 5.45688ZM45.2781 21.7563L34.2437 10.7219L36.6803 8.28531C39.7273 5.23823 44.6676 5.23823 47.7147 8.28531C50.7618 11.3324 50.7618 16.2727 47.7147 19.3197L45.2781 21.7563Z" clipRule="evenodd"></path>
                      <path fill={selectedOrganization === 'Della' ? '#1F75E0' : selectedOrganization === 'Vezita' ? '#F98A00' : '#01497C'} d="M62 60C62 61.1046 61.1046 62 60 62L4 62C2.89543 62 2 61.1046 2 60C2 58.8954 2.89543 58 4 58L60 58C61.1046 58 62 58.8954 62 60Z"></path>
                    </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            :
            <span className='NoCandidates'> No candidates </span>
          }
        </section>
        <section className='PaginationContainer'>
          <div className='Pagination'>
            <button className={`NextPrev ${RequestPage === 1 ? 'disabled' : ''}`} disabled={RequestPage === 1} onClick={() => { setRequestPage(prevPage => prevPage - 1) }}> Prev </button>
            {pageNumbers.map((pageNumber, index) => (
              <React.Fragment key={index}> {pageNumber === ellipsis ? (
                <span> {ellipsis} </span>
              ) : (
                <button className={`PageNo ${pageNumber === RequestPage ? 'disabled CurrentPage' : ''}`} onClick={() => { setRequestPage(pageNumber) }} disabled={pageNumber === RequestPage}> {pageNumber} </button>
              )}
              </React.Fragment>
            ))}
            <button className={`NextPrev ${RequestPage === TotalNoOfPages ? 'disabled' : ''}`} disabled={RequestPage === TotalNoOfPages} onClick={() => { setRequestPage(prevPage => prevPage + 1) }}> Next </button>
          </div>
          <div className='ItemsPerPage'>
            <label className='OptionsLabel'> Count </label>
            <select className='Options' value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value={25}> 25 </option>
              <option value={50}> 50 </option>
              <option value={100}> 100 </option>
            </select>
          </div>
        </section>
        {Edit &&
          <section className='EditPlacementsWrapper'>
            <EditPlacement organization={selectedOrganization} data={TranferEditdata} UpdatePlacement={updatePlacement} ToggleEdit={ToggleEditPlacement}/>
          </section>
        }
      </section>
    </main>
  )
}

export default PlacementsList