import React, { useEffect, useState } from 'react'
import './RevenueStats.scss'
import ResponsiveBarChart from '../../../Charts/ResponsiveBarChart'
import { useDispatch } from 'react-redux'
import { GetMonthlyRevenue, GetQuarterlyRevenue } from '../../../redux/action/dashboardaction'
import Loader from '../../Loader'

const RevenueStats = ({organization}) => {
  const selectedOrganization = organization
  const dispatch = useDispatch()
  const [QuarterData, setQuarterData] = useState('')
  const [MonthlyData, setMonthlyData] = useState('')


  const GetQuarterData = () => {
    const data = {
      "year": ['2022', '2023', '2024'],
      "organization": selectedOrganization === 'Della'? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    console.log("RequestedData:::",data)
    const successFn = (response) => {
      console.log('Response::::', response.data);
      setQuarterData(response.data)
    };
  
    const failureFn = (error) => {
      console.error('Response failed:', error);
      alert(error);
    };
    dispatch(GetQuarterlyRevenue({ data, successFn, failureFn }));
  };

  const GetMonthlyData = () => {
    const data = {
      "year": ['2022', '2023', '2024'],
      "organization": selectedOrganization === 'Della'? 'della' : selectedOrganization === 'Vezita' ? 'vezita' : 'vrd canada'
    }
    console.log("RequestedData:::",data)
    const successFn = (response) => {
      console.log('Response::::', response.data);
      setMonthlyData(response.data)
    };
  
    const failureFn = (error) => {
      console.error('Response failed:', error);
      alert(error);
    };
    dispatch(GetMonthlyRevenue({ data, successFn, failureFn }));
  };


  useEffect(()=> {
    GetQuarterData()
    GetMonthlyData()
  },[selectedOrganization])



  const QTR22 = QuarterData && QuarterData[0].Quarter_Yearly_wise_data[0].quarterly_yearly_without_client

  const Quarter2022 = Object.keys(QTR22).map(quarter => ({
    Quarter: quarter,
    Revenue: QTR22[quarter]
  }));

  const QTR23 = QuarterData && QuarterData[1].Quarter_Yearly_wise_data[0].quarterly_yearly_without_client

  const Quarter2023 = Object.keys(QTR23).map(quarter => ({
    Quarter: quarter,
    Revenue: QTR23[quarter]
  }));

  const QTR24 = QuarterData && QuarterData[2].Quarter_Yearly_wise_data[0].quarterly_yearly_without_client

  const Quarter2024 = Object.keys(QTR24).map(quarter => ({
    Quarter: quarter,
    Revenue: QTR24[quarter]
  }));

  const MTH22 = MonthlyData && MonthlyData[0].Monthly_Year_wise_data[0].monthly_yearly_without_client

  const Monthly2022 = Object.keys(MTH22).map(month => ({
    Quarter: month,
    Revenue: MTH22[month]
  }));

  const MTH23 = MonthlyData && MonthlyData[1].Monthly_Year_wise_data[0].monthly_yearly_without_client

  const Monthly2023 = Object.keys(MTH23).map(month => ({
    Quarter: month,
    Revenue: MTH23[month]
  }));

  const MTH24 = MonthlyData && MonthlyData[2].Monthly_Year_wise_data[0].monthly_yearly_without_client

  const Monthly2024 = Object.keys(MTH24).map(month => ({
    Quarter: month,
    Revenue: MTH24[month]
  }));


  const QuarterlyRevenue = [
    {
      "Quarter": "First Qtr",
      "Revenue": 20,
    },
    {
      "Quarter": "Second Qtr",
      "Revenue": 96,
    },
    {
      "Quarter": "Third Qtr",
      "Revenue": 152,
    },
    {
      "Quarter": "Fourth Qtr",
      "Revenue": 152,
    }
  ]

  const MonthlyRevenue = [
    {
      "Month": "Jan",
      "Revenue": 80,
    },
    {
      "Month": "Feb",
      "Revenue": 86,
    },
    {
      "Month": "Mar",
      "Revenue": 122,
    },
    {
      "Month": "Apr",
      "Revenue": 171,
    },
    {
      "Month": "May",
      "Revenue": 130,
    },
    {
      "Month": "Jun",
      "Revenue": 96,
    },
    {
      "Month": "Jul",
      "Revenue": 142,
    },
    {
      "Month": "Aug",
      "Revenue": 121,
    },
    {
      "Month": "Sep",
      "Revenue": 100,
    },
    {
      "Month": "Oct",
      "Revenue": 96,
    },
    {
      "Month": "Nov",
      "Revenue": 102,
    },
    {
      "Month": "Dec",
      "Revenue": 141,
    },
  ]

  const ClientComparisonRevenue = [
    {
      "Client": "Client1",
      "Revenue": 20,
    },
    {
      "Client": "Client2",
      "Revenue": 96,
    },
    {
      "Client": "Client3",
      "Revenue": 152,
    },
    {
      "Client": "Client4",
      "Revenue": 152,
    },
    {
      "Client": "Client5",
      "Revenue": 20,
    },
    {
      "Client": "Client6",
      "Revenue": 96,
    },
    {
      "Client": "Client7",
      "Revenue": 152,
    },
    {
      "Client": "Client8",
      "Revenue": 152,
    },
    {
      "Client": "Client9",
      "Revenue": 20,
    },
    {
      "Client": "Client10",
      "Revenue": 96,
    },
    {
      "Client": "Client11",
      "Revenue": 152,
    },
    {
      "Client": "Client12",
      "Revenue": 152,
    },
    {
      "Client": "Client13",
      "Revenue": 20,
    },
    {
      "Client": "Client14",
      "Revenue": 96,
    },
    {
      "Client": "Client15",
      "Revenue": 152,
    },
    {
      "Client": "Client16",
      "Revenue": 152,
    },
    {
      "Client": "Client17",
      "Revenue": 20,
    },
    {
      "Client": "Client18",
      "Revenue": 96,
    },
    {
      "Client": "Client19",
      "Revenue": 152,
    },
    {
      "Client": "Client20",
      "Revenue": 152,
    },
    {
      "Client": "Client21",
      "Revenue": 20,
    },
    {
      "Client": "Client22",
      "Revenue": 96,
    },
    {
      "Client": "Client23",
      "Revenue": 152,
    },
    {
      "Client": "Client24",
      "Revenue": 152,
    },
    {
      "Client": "Client25",
      "Revenue": 20,
    },
    {
      "Client": "Client26",
      "Revenue": 96,
    },
    {
      "Client": "Client27",
      "Revenue": 152,
    },
    {
      "Client": "Client28",
      "Revenue": 152,
    },
    {
      "Client": "Client29",
      "Revenue": 20,
    },
    {
      "Client": "Client30",
      "Revenue": 96,
    },
    {
      "Client": "Client31",
      "Revenue": 152,
    },
    {
      "Client": "Client32",
      "Revenue": 152,
    },
  ]

  const Della = '#1F75E0';
  const Vezita = '#F98A00';
  const VRDCanada = '#01497C';

  return (
    <section className="RevenueStats">
      <section className='Quarter'>
        <article className={`QuarterlyRevenue ${selectedOrganization}`}>
          {QuarterData ? 
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={Quarter2022} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
            :
            <Loader organization={selectedOrganization}/>
          }
          <span className='Title'>
            Quarterly Revenue - 2022
          </span>
        </article>
        <article className={`QuarterlyRevenue ${selectedOrganization}`}>
          {QuarterData ? 
          <div className='ChartContainer'>
            <ResponsiveBarChart datainput={Quarter2023} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
          </div>
            :
            <Loader organization={selectedOrganization}/>
          }
          <span className='Title'>
            Quarterly Revenue - 2023
          </span>
        </article>
        <article className={`QuarterlyRevenue ${selectedOrganization}`}>
          {QuarterData ? 
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={Quarter2024} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
            :
            <Loader organization={selectedOrganization}/>
          }
          <span className='Title'>
            Quarterly Revenue - 2024
          </span>
        </article>
      </section>
      <section className='Quarter'>
        <article className={`QuarterlyRevenue ${selectedOrganization}`}>
          {MonthlyData ? 
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={Monthly2022} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
            :
            <Loader organization={selectedOrganization}/>
          }
          <span className='Title'>
            Monthly Revenue - 2022
          </span>
        </article>
        <article className={`QuarterlyRevenue ${selectedOrganization}`}>
          {MonthlyData ? 
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={Monthly2023} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
            :
            <Loader organization={selectedOrganization}/>
          }
          <span className='Title'>
            Monthly Revenue - 2023
          </span>
        </article>
        <article className={`QuarterlyRevenue ${selectedOrganization}`}>
          {MonthlyData ? 
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={Monthly2024} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
            </div>
            :
            <Loader organization={selectedOrganization}/>
          }
          <span className='Title'>
            Monthly Revenue - 2024
          </span>
        </article>
      </section>
      <section className='ClientsTopTen'>
        <h1 className='SubHeader'> Top 10 Clients (based on Revenue) </h1>
        <section className='TopTen'>
          <article className={`QuarterlyRevenue ${selectedOrganization}`}>
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={QuarterlyRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada} layout={'horizontal'}/>
            </div>
            <span className='Title'> Top ten Clients - 2022 </span>
          </article>
          <article className={`QuarterlyRevenue ${selectedOrganization}`}>
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={QuarterlyRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada} layout={'horizontal'}/>
            </div>
            <span className='Title'> Top ten Clients - 2023 </span>
          </article>
          <article className={`QuarterlyRevenue ${selectedOrganization}`}>
            <div className='ChartContainer'>
              <ResponsiveBarChart datainput={QuarterlyRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada} layout={'horizontal'}/>
            </div>
            <span className='Title'> Top ten Clients - 2024 </span>
          </article>
        </section>
      </section>
      <section className='RevenueComparison'>
        <h1 className='SubHeader'> Revenue Comparison - Clients </h1>
        <article className={`QuarterlyRevenue ${selectedOrganization}`}>
          <div className='ChartContainer'>
            <ResponsiveBarChart datainput={ClientComparisonRevenue} organization={selectedOrganization} colors={selectedOrganization === 'Della' ? Della : selectedOrganization === 'Vezita' ? Vezita : VRDCanada}/>
          </div>
        </article>
      </section>
    </section>
  )
}

export default RevenueStats