import React, { useState } from 'react'
import './QuarterlyStats.scss'
import ResponsivePieChart from '../../../Charts/ResponsivePieChart'
import ResponsiveBarChart from '../../../Charts/ResponsiveBarChart'

const QuarterlyStats = ({organization}) => {
  const selectedOrganization = organization
  const [selectedClient, setSelectedClient] = useState('')

  const handleSelectionChange = (e) => {
    setSelectedClient(e.target.value)
  }

  const RecruiterCandidates = [
    {
      "id": "Client1 Revenue",
      "value": 20,
    },
    {
      "id": "Overall Revenue",
      "value": 100,
    }
  ]

  const ManufacturingActiveMargindata = [
    {
      "Quarter": "First Qtr",
      "Revenue": 80,
    },
    {
      "Quarter": "Second Qtr",
      "Revenue": 86,
    },
    {
      "Quarter": "Third Qtr",
      "Revenue": 122,
    },
    {
      "Quarter": "Fourth Qtr",
      "Revenue": 171,
    }
  ]

  const Della = ['#1F75E0', '#72AAF020'];
  const Vezita = ['#F98A00', '#FFD09320'];
  const VRDCanada = ['#01497C', '#61A5C220'];

  return (
    <main className='QuarterlyStats'>
      <header className='HeaderSelection'>
        <h1 className='Header'> Comparison of Quarterly Contribution {`${selectedClient !== '' ? `- ${selectedClient}` : ''}`} </h1>
        <label htmlFor="Client" className='LabelSelect'>
          Select Client
          <select id='Client' className='Select' onChange={handleSelectionChange}>
            <option value={'Client 1'}> Client 1 </option>
            <option value={'Client 2'}> Client 2 </option>
          </select>
        </label>
      </header>
      <section className='StatsContainer'>
        <article className={`ContributionPie ${selectedOrganization}`}>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
          <div className='PieChartContainer'>
            <ResponsivePieChart datainput={RecruiterCandidates} color={selectedOrganization === "Della" ? Della : selectedOrganization === "Vezita" ? Vezita : VRDCanada} InnerRadius={0.7}/>
            <label> 100% </label>
          </div>
        </article>
        <article className={`BarChartContainer ${selectedOrganization}`}>
          <div className='BarChart'>
            <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
            <span className='Title'> Revenue Contribution - 2022 </span>
          </div>
          <div className='BarChart'>
            <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
            <span className='Title'> Revenue Contribution - 2023 </span>
          </div>
        </article>
      </section>
      <section className='ClientsContributionProgress'>
        <h1 className='ClientsContributionHeader'>
          Clients Revenue Contribution and Progress - Quarterly
        </h1>
        <article className='ContributionProgress'>
          <div className={`Client ${selectedOrganization}`}>
            <h3 className='Title'> Client Name 1 </h3>
            <div className='LabelChart'>
              <label className='Label'> Quarterly Revenue </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
            <div className='LabelChart'>
              <label className='Label'> Revenue Progress </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
          </div>
          <div className={`Client ${selectedOrganization}`}>
            <h3 className='Title'> Client Name 1 </h3>
            <div className='LabelChart'>
              <label className='Label'> Quarterly Revenue </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
            <div className='LabelChart'>
              <label className='Label'> Revenue Progress </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
          </div>
          <div className={`Client ${selectedOrganization}`}>
            <h3 className='Title'> Client Name 1 </h3>
            <div className='LabelChart'>
              <label className='Label'> Quarterly Revenue </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
            <div className='LabelChart'>
              <label className='Label'> Revenue Progress </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
          </div>
          <div className={`Client ${selectedOrganization}`}>
            <h3 className='Title'> Client Name 1 </h3>
            <div className='LabelChart'>
              <label className='Label'> Quarterly Revenue </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
            <div className='LabelChart'>
              <label className='Label'> Revenue Progress </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
          </div>
          <div className={`Client ${selectedOrganization}`}>
            <h3 className='Title'> Client Name 1 </h3>
            <div className='LabelChart'>
              <label className='Label'> Quarterly Revenue </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
            <div className='LabelChart'>
              <label className='Label'> Revenue Progress </label>
              <div className='Chart'>
                <ResponsiveBarChart organization={selectedOrganization} datainput={ManufacturingActiveMargindata} colors={selectedOrganization === "Della" ? Della[0] : selectedOrganization === "Vezita" ? Vezita[0] : VRDCanada[0]}/>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section className='FooterSection'>
        <header className="Header"> Revenue Contribution Details {`${selectedClient !== '' ? `- ${selectedClient}` : ''}`} </header>
        <table className="TableContainer">
          <thead className="HeaderContainer">
            <tr className={selectedOrganization}>
              <th> Candidate Name </th>
              <th> Start Date </th>
              <th> End Date </th>
              <th> Client </th>
              <th> Recruiter </th>
              <th> Gross Margin </th>
            </tr>
          </thead>
          <tbody className="ValueContainer">
            <tr className={selectedOrganization}>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
            </tr>
            <tr className={selectedOrganization}>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
              <td> Kalaiselvan </td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default QuarterlyStats