import { configureStore } from "@reduxjs/toolkit";
import { reducers as ActiveInactive } from '../redux/slice/dashboardslice'

const store = configureStore({
  reducer: {
    ActiveInactive: ActiveInactive.ActiveInactive,
    CreateNewPlacement: ActiveInactive.CreateNewPlacement,
    RequestedActiveInactive: ActiveInactive.RequestedActiveInactive,
    DailyMarginCandidates: ActiveInactive.DailyMarginCandidates,
    WeeklyMarginCandidates: ActiveInactive.WeeklyMarginCandidates,
    RecruitersClientsList: ActiveInactive.RecruitersClientsList,
    GetCandidatesWeek: ActiveInactive.GetCandidatesWeek,
    GetPlacementsList: ActiveInactive.GetPlacementsList,
    UpdatePlacement: ActiveInactive.UpdatePlacement,
    GetProjectedEnd: ActiveInactive.GetProjectedEnd,
    SearchPlacement: ActiveInactive.SearchPlacement,
    GetOnboardingReferences: ActiveInactive.GetOnboardingReferences,
    AddOnboardingReferences: ActiveInactive.AddOnboardingReferences,
    UpdateOnboardingReferences: ActiveInactive.UpdateOnboardingReferences,
    UploadOnboardingDoc: ActiveInactive.UploadOnboardingDoc,
    GetOnboardingList: ActiveInactive.GetOnboardingList,
    AddOnboardingCandidate: ActiveInactive.AddOnboardingCandidate,
    GetClientFacilityList: ActiveInactive.GetClientFacilityList,
    GetClientFacilityRequiredDocsList: ActiveInactive.GetClientFacilityRequiredDocsList,

    GetQuarterlyRevenue: ActiveInactive.GetQuarterlyRevenue,
    GetMonthlyRevenue: ActiveInactive.GetMonthlyRevenue,

    UpdateTimesheets: ActiveInactive.UpdateTimesheets,
  },
});

export default store;