import React, { useState } from 'react'
import './Login.scss'
import Logo from '../Logo.svg'

const Login = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState()
  const [ToggleIcon, setToggleIcon] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('dummy_username', username);
    localStorage.setItem('dummy_password', password);
    window.location.reload()
  }

  const handleInputUser = (e) => {
    setUsername(e.target.value)
  }

  const handleInputpassword = (e) => {
    setPassword(e.target.value)
  }

  return (
    <div className='Login'>
      <div className='LogoForm'>
        <img src={Logo} alt='' draggable={false}/>
        <form className='LoginForm' onSubmit={handleSubmit}>
          <div className='TextInputBottomline'>
            <input type='email' className='inputCustom'
              value={username} required
              onChange={handleInputUser}/>
            <label className='label'>User email</label>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='Icon'>
              <path d="M10 8.75C10.7417 8.75 11.4667 8.53007 12.0834 8.11801C12.7001 7.70596 13.1807 7.12029 13.4645 6.43506C13.7484 5.74984 13.8226 4.99584 13.6779 4.26841C13.5333 3.54098 13.1761 2.8728 12.6517 2.34835C12.1272 1.8239 11.459 1.46675 10.7316 1.32206C10.0042 1.17736 9.25016 1.25162 8.56494 1.53545C7.87971 1.81928 7.29404 2.29993 6.88199 2.91661C6.46993 3.5333 6.25 4.25832 6.25 5C6.25 5.99456 6.64509 6.94839 7.34835 7.65165C8.05161 8.35491 9.00544 8.75 10 8.75ZM10 2.5C10.4945 2.5 10.9778 2.64662 11.3889 2.92133C11.8 3.19603 12.1205 3.58648 12.3097 4.04329C12.4989 4.50011 12.5484 5.00277 12.452 5.48773C12.3555 5.97268 12.1174 6.41814 11.7678 6.76777C11.4181 7.1174 10.9727 7.3555 10.4877 7.45197C10.0028 7.54843 9.50011 7.49892 9.04329 7.3097C8.58648 7.12048 8.19603 6.80005 7.92133 6.38893C7.64662 5.9778 7.5 5.49445 7.5 5C7.5 4.33696 7.76339 3.70108 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5ZM10 10C8.3424 10 6.75269 10.6585 5.58058 11.8306C4.40848 13.0027 3.75 14.5924 3.75 16.25V18.125C3.75 18.2908 3.81585 18.4497 3.93306 18.5669C4.05027 18.6842 4.20924 18.75 4.375 18.75C4.54076 18.75 4.69973 18.6842 4.81694 18.5669C4.93415 18.4497 5 18.2908 5 18.125V16.25C5 14.9239 5.52678 13.6521 6.46447 12.7145C7.40215 11.7768 8.67392 11.25 10 11.25C11.3261 11.25 12.5979 11.7768 13.5355 12.7145C14.4732 13.6521 15 14.9239 15 16.25V18.125C15 18.2908 15.0658 18.4497 15.1831 18.5669C15.3003 18.6842 15.4592 18.75 15.625 18.75C15.7908 18.75 15.9497 18.6842 16.0669 18.5669C16.1842 18.4497 16.25 18.2908 16.25 18.125V16.25C16.25 14.5924 15.5915 13.0027 14.4194 11.8306C13.2473 10.6585 11.6576 10 10 10Z" fill="#3A59BD"/>
            </svg>
          </div>
          <div className='TextInputBottomline'>
            <input type={ToggleIcon ? 'text' : 'password'} className='inputCustom'
            value={password} required
            onChange={handleInputpassword}/>
            <label className='label'>Password</label>
            {ToggleIcon ?
            (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none" className='Icon' onClick={() => {setToggleIcon(!ToggleIcon)}}>
              <path d="M10 1.37439C3.75 1.37439 1.25 7 1.25 7C1.25 7 3.75 12.6244 10 12.6244C16.25 12.6244 18.75 7 18.75 7C18.75 7 16.25 1.37439 10 1.37439Z" stroke="#3A59BD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 10.1251C11.7259 10.1251 13.125 8.72597 13.125 7.00008C13.125 5.27419 11.7259 3.87508 10 3.87508C8.27411 3.87508 6.875 5.27419 6.875 7.00008C6.875 8.72597 8.27411 10.1251 10 10.1251Z" stroke="#3A59BD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>)
            :
            (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none" className='Icon' onClick={() => {setToggleIcon(!ToggleIcon)}}>
              <path d="M15.7148 4.9457L17.4969 6.9457M12.0455 6.66117L12.6013 8.66117M7.94764 6.65969L7.39178 8.65969M4.28196 4.94312L2.49133 6.94312M1 1C1 1 3.5 6.62439 9.75 6.62439C16 6.62439 18.5 1 18.5 1" stroke="#3A59BD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>)}
          </div>
          <button className='Button'>Log In</button>
        </form>
      </div>
    </div>
  )
}

export default Login