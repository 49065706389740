import { createSlice } from "@reduxjs/toolkit";
import { ActiveInactive, CreateNewPlacement, RequestedActiveInactive, DailyMarginCandidates, WeeklyMarginCandidates, RecruitersClientsList, GetCandidatesWeek, GetPlacementsList, UpdatePlacement, GetQuarterlyRevenue, GetMonthlyRevenue, UpdateTimesheets, GetProjectedEnd, SearchPlacement, GetOnboardingReferences, AddOnboardingReferences, GetOnboardingList, GetClientFacilityList, GetClientFacilityRequiredDocsList, AddOnboardingCandidate, UpdateOnboardingReferences, UploadOnboardingDoc } from "../action/dashboardaction";


const ActiveInactiveslice = createSlice({
  name: 'ActiveInactive',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ActiveInactive.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ActiveInactive.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(ActiveInactive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const CreateNewPlacementslice = createSlice({
  name: 'CreateNewPlacement',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateNewPlacement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CreateNewPlacement.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(CreateNewPlacement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const RequestedActiveInactiveslice = createSlice({
  name: 'RequestedActiveInactive',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RequestedActiveInactive.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(RequestedActiveInactive.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(RequestedActiveInactive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const DailyMarginCandidatesslice = createSlice({
  name: 'DailyMarginCandidates',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DailyMarginCandidates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DailyMarginCandidates.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(DailyMarginCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const WeeklyMarginCandidatesslice = createSlice({
  name: 'WeeklyMarginCandidates',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(WeeklyMarginCandidates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(WeeklyMarginCandidates.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(WeeklyMarginCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const RecruitersClientsListslice = createSlice({
  name: 'RecruitersClientsList',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RecruitersClientsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(RecruitersClientsList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(RecruitersClientsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetCandidatesWeekslice = createSlice({
  name: 'GetCandidatesWeek',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCandidatesWeek.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCandidatesWeek.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetCandidatesWeek.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetPlacementsListslice = createSlice({
  name: 'GetPlacementsList',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetPlacementsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPlacementsList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetPlacementsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const UpdatePlacementslice = createSlice({
  name: 'UpdatePlacement',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdatePlacement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdatePlacement.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(UpdatePlacement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetQuarterlyRevenueslice = createSlice({
  name: 'GetQuarterlyRevenue',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetQuarterlyRevenue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetQuarterlyRevenue.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetQuarterlyRevenue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetMonthlyRevenueslice = createSlice({
  name: 'GetMonthlyRevenue',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetMonthlyRevenue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetMonthlyRevenue.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetMonthlyRevenue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const UpdateTimesheetsslice = createSlice({
  name: 'UpdateTimesheets',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateTimesheets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateTimesheets.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(UpdateTimesheets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetProjectedEndslice = createSlice({
  name: 'GetProjectedEnd',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetProjectedEnd.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetProjectedEnd.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetProjectedEnd.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const SearchPlacementslice = createSlice({
  name: 'SearchPlacement',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SearchPlacement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SearchPlacement.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(SearchPlacement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetOnboardingReferencesslice = createSlice({
  name: 'GetOnboardingReferences',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetOnboardingReferences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetOnboardingReferences.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetOnboardingReferences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const AddOnboardingReferencesslice = createSlice({
  name: 'AddOnboardingReferences',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddOnboardingReferences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddOnboardingReferences.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(AddOnboardingReferences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetOnboardingListslice = createSlice({
  name: 'GetOnboardingList',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetOnboardingList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetOnboardingList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetOnboardingList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetClientFacilityListslice = createSlice({
  name: 'GetClientFacilityList',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetClientFacilityList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetClientFacilityList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetClientFacilityList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const GetClientFacilityRequiredDocsListslice = createSlice({
  name: 'GetClientFacilityRequiredDocsList',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetClientFacilityRequiredDocsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetClientFacilityRequiredDocsList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(GetClientFacilityRequiredDocsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const AddOnboardingCandidateslice = createSlice({
  name: 'AddOnboardingCandidate',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddOnboardingCandidate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddOnboardingCandidate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(AddOnboardingCandidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const UpdateOnboardingReferencesslice = createSlice({
  name: 'UpdateOnboardingReferences',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateOnboardingReferences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateOnboardingReferences.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(UpdateOnboardingReferences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});


const UploadOnboardingDocslice = createSlice({
  name: 'UploadOnboardingDoc',
  initialState: {
    loading: false,
    data: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UploadOnboardingDoc.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UploadOnboardingDoc.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === 'function') {
          action.meta.arg.successFn(action.payload);
          // console.log("action page", action.payload)
        }
      })
      .addCase(UploadOnboardingDoc.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;

        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === 'function') {
          action.meta.arg.failureFn(action.error.message);
          // console.log("action:::", action.error.message)
        }
      });
  }
});

export const reducers = {
  ActiveInactive : ActiveInactiveslice.reducer,
  CreateNewPlacement : CreateNewPlacementslice.reducer,
  RequestedActiveInactive : RequestedActiveInactiveslice.reducer,
  DailyMarginCandidates : DailyMarginCandidatesslice.reducer,
  WeeklyMarginCandidates : WeeklyMarginCandidatesslice.reducer,
  RecruitersClientsList : RecruitersClientsListslice.reducer,
  GetCandidatesWeek : GetCandidatesWeekslice.reducer,
  GetPlacementsList : GetPlacementsListslice.reducer,
  UpdatePlacement : UpdatePlacementslice.reducer,
  GetProjectedEnd : GetProjectedEndslice.reducer,
  SearchPlacement : SearchPlacementslice.reducer,
  GetOnboardingReferences : GetOnboardingReferencesslice.reducer,
  AddOnboardingReferences : AddOnboardingReferencesslice.reducer,
  UpdateOnboardingReferences : UpdateOnboardingReferencesslice.reducer,
  UploadOnboardingDoc : UploadOnboardingDocslice.reducer,
  GetOnboardingList : GetOnboardingListslice.reducer,
  AddOnboardingCandidate : AddOnboardingCandidateslice.reducer,
  AddOnboardingCandidate : AddOnboardingCandidateslice.reducer,
  GetClientFacilityList : GetClientFacilityListslice.reducer,
  GetClientFacilityRequiredDocsList : GetClientFacilityRequiredDocsListslice.reducer,

  GetQuarterlyRevenue : GetQuarterlyRevenueslice.reducer,
  GetMonthlyRevenue : GetMonthlyRevenueslice.reducer,
  
  UpdateTimesheets : UpdateTimesheetsslice.reducer,
};