import React from 'react'
import { ResponsiveLine } from '@nivo/line'

const ResponsiveLineChart = ({ color, Data, handlePointClick }) => {
  const Color = color
  const data = Data

  // const handlePointClick = (point, event) => {
  //   console.log('Clicked Line Data:', point);
  // };

  return (
    <ResponsiveLine
        data={data}
        colors={Color}
        margin={{ top: 40, right: 80, bottom: 60, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: false,
            reverse: false
        }}
        pointSize={8}
        // enablePointLabel={true}
        // pointLabelYOffset={-12}
        // pointColor={{ from: 'serieColor' }}
        // pointBorderWidth={2}
        // pointBorderColor={{ from: 'serieColor' }}
        useMesh={true}
        // enableCrosshair={false}
        axisBottom={{
          tickRotation: -90
        }}
        isInteractive={true}
        enableGridY={false}
        enableGridX={false}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  }
              }
            ]
          }
        ]}
        onClick={handlePointClick}
    />
  )
}

export default ResponsiveLineChart