import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import store from './redux/store';
import Routeindex from './routes/routeindex';
import Loader from './StatsDashboard/Loader';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={ <Loader organization={'Della'}/> }>
          <Routeindex/>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
